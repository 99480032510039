/*
 * Reducers for adding and removing user-selected genes
 *
 */
import { NEXT_TUTORIAL, PREVIOUS_TUTORIAL, START_TUTORIAL, STOP_TUTORIAL }
  from '../actions/TutorialActions'

function tutorial(state = {
  step: 0
}, action) {
  switch (action.type) {
    case NEXT_TUTORIAL:
      return Object.assign({}, state, {
        step: state.step + 1,
      })
    case START_TUTORIAL:
      return Object.assign({}, state, {
        step: 1,
      })
    case STOP_TUTORIAL:
      return Object.assign({}, state, {
        step: 0,
      })
    case PREVIOUS_TUTORIAL:
      return Object.assign({}, state, {
        step: state.step - 1,
      })
    default:
      return state;
  }
}

var tutorialReducers = {
  tutorial
};

export default tutorialReducers;
