/* eslint-disable camelcase */ // API returns many snakecase values
import { assign, send } from 'xstate';
import { GeneMatch, OrganismSlugString } from '../../shared/sharedTypes';
import { SeekMachineContext, SeekMachineEvent } from '../seekTypes';
import history from '../../../history';
import { SEEK_PAGE } from '../../../settings';

import { getSeekBodyTag, getNewDefaultContext } from './seekUtils';

const assignEntrezDetailResult = assign(
  (context: SeekMachineContext, event: SeekMachineEvent) => {
    // @ts-expect-error - REVISIT in V5 - https://xstate.js.org/docs/guides/typescript.html#troubleshooting
    const { data } = event;

    // no result found on server, empty
    if ('detail' in data) return { expressionGeneDetail: {} };

    return {
      expressionGeneDetail: data.reduce(
        (
          acc: { [key: string]: GeneMatch },
          geneResult: { matches: GeneMatch[] },
        ) => {
          const entrezDetail = geneResult.matches[0];
          acc[entrezDetail.entrez] = entrezDetail;
          return acc;
        },
        {},
      ),
    };
  },
);

const assignError = assign({
  errors: (context: SeekMachineContext, event: SeekMachineEvent) => {
    const { errors } = context;
    // @ts-expect-error - REVISIT in V5 - https://xstate.js.org/docs/guides/typescript.html#troubleshooting
    const { name, message } = event;
    return { ...errors, [name]: message };
  },
});

const assignExpressionGeneFilterText = assign({
  expressionGeneFilterText: (_, event: SeekMachineEvent): string => {
    // @ts-expect-error - REVISIT in V5 - https://xstate.js.org/docs/guides/typescript.html#troubleshooting
    return event.expressionGeneFilterText;
  },
});

const assignPages = assign(
  (context: SeekMachineContext, event: SeekMachineEvent) => {
    const { referenceOrganismSlug, page, pageY } = context;
    const newPage = Object.assign({}, page, {
      // @ts-expect-error - REVISIT in V5 - https://xstate.js.org/docs/guides/typescript.html#troubleshooting
      [referenceOrganismSlug]: event.pageNumber,
    });
    const newPageY = Object.assign({}, pageY, {
      // @ts-expect-error - REVISIT in V5 - https://xstate.js.org/docs/guides/typescript.html#troubleshooting
      [referenceOrganismSlug]: event.pageYNumber,
    });
    return { page: newPage, pageY: newPageY };
  },
);

const assignQueryOrganismSlug = assign({
  queryOrganismSlug: (
    context: SeekMachineContext,
    event: SeekMachineEvent,
    { state },
  ) => {
    if (event.type === 'MANUAL') return event.queryOrganismSlug;

    // Automatically select species with the highest number of matches in query genes
    if (
      state?.matches('queryOrganismSelectMethod.automatic') &&
      event.type === 'SET_NEW_SEEK_QUERY_GENES'
    ) {
      return event.topOrganismSlug;
    }

    // Otherwise leave the selected organism 'as is'
    return context.queryOrganismSlug;
  },
});

const assignSelectedDatasets = assign((_, event: SeekMachineEvent) => {
  // @ts-expect-error - REVISIT in V5 - https://xstate.js.org/docs/guides/typescript.html#troubleshooting
  const { selectedDatasets } = event;
  return { selectedDatasets };
});

const assignAvailableDatasets = assign({
  availableDatasets: (
    context: SeekMachineContext,
    event: SeekMachineEvent,
    { state },
  ) => {
    if (
      (state?.matches(
        'expressionQueryManager.modSEEKDatasetManager.hasModSEEKDatasetHash.yes',
      ) &&
        event.type === 'SET_SEEK_RESULT') ||
      (state?.matches('seekResultManager.hasSeekResult.yes') &&
        event.type === 'SET_HAS_MODSEEK_DATASET_HASH') ||
      (state?.matches('seekResultManager.hasSeekResult.yes') &&
        state?.matches(
          'expressionQueryManager.modSEEKDatasetManager.hasModSEEKDatasetHash.yes',
        ))
    ) {
      const { seekResult, referenceOrganismSlug, datasetOrgHash } = context;
      const { dataset_weights: datasetWeights } = seekResult[
        referenceOrganismSlug
      ];
      const data = datasetOrgHash[referenceOrganismSlug];

      return datasetWeights.reduce((acc: any, [seekDatasetId]) => {
        const hbId = seekDatasetId.split('.')[0];
        if (hbId in data) {
          acc[hbId] = data[hbId];
        }
        return acc;
      }, {});
    }

    return context.availableDatasets;
  },
});

const assignModSEEKDatasetOrgHash = assign((_, event: SeekMachineEvent) => {
  // @ts-expect-error - REVISIT in V5 - https://xstate.js.org/docs/guides/typescript.html#troubleshooting
  const { data } = event;
  return { datasetOrgHash: data };
});

const assignSeekResult = assign((_, event: SeekMachineEvent) => {
  // @ts-expect-error - REVISIT in V5 - https://xstate.js.org/docs/guides/typescript.html#troubleshooting
  const { query, seek_results } = event.data;
  const {
    query_genes: queryGenes,
    query_organism: queryOrganismSlug,
    term_list: selectedTerms,
  } = query;

  const queryEntrez = queryGenes.map((gene: any) => gene.entrez);
  return {
    seekResult: seek_results,
    referenceOrganismSlug: queryOrganismSlug,
    queryGeneMatches: queryGenes,
    queryEntrez,
    queryOrganismSlug,
    selectedTerms,
  };
});

const assignExpressionResult = assign(
  (context: SeekMachineContext, event: SeekMachineEvent) => {
    const { referenceOrganismSlug, expressionResults, page } = context;
    const currentPage = page[referenceOrganismSlug];
    // @ts-expect-error - REVISIT in V5 - https://xstate.js.org/docs/guides/typescript.html#troubleshooting
    expressionResults[referenceOrganismSlug][currentPage] = event.data;
    return { expressionResults: Object.assign({}, expressionResults) };
  },
);

const assignEnrichmentResult = assign(
  (context: SeekMachineContext, event: SeekMachineEvent) => ({
    // @ts-expect-error - REVISIT in V5 - https://xstate.js.org/docs/guides/typescript.html#troubleshooting
    enrichmentResult: event.data,
  }),
);

const assignNewSeekQueryGenes = assign(
  // @ts-expect-error - REVISIT in V5 - https://xstate.js.org/docs/guides/typescript.html#troubleshooting
  (context: SeekMachineContext, event: SeekMachineEvent) => {
    if (event.type !== 'SET_NEW_SEEK_QUERY_GENES')
      return {
        queryGeneMatches: context.queryGeneMatches,
      };
    return {
      queryEntrez: [
        ...Array.from(
          new Set(event.queryGeneMatches.map(geneMatch => geneMatch.entrez)),
        ),
      ],
      queryGeneMatches: event.queryGeneMatches,
    };
  },
);
const assignExpressionFilterMatches = assign({
  expressionFilterMatches: (
    context: SeekMachineContext,
    event: SeekMachineEvent,
  ) => {
    if (event.type !== 'NEW_EXPRESSION_GENE_FILTER_MATCHES')
      return context.expressionFilterMatches;
    return event.expressionFilterMatches;
  },
});

const assignGeneCount = assign({
  geneCount: (context: SeekMachineContext, event: SeekMachineEvent): number => {
    if (event.type === 'UPDATE_SEEK_FMD_GENE_COUNT') {
      return event.geneCount;
    }
    return context.geneCount;
  },
});

const assignReferenceOrganism = assign({
  referenceOrganismSlug: (
    context: SeekMachineContext,
    event: SeekMachineEvent,
  ): OrganismSlugString =>
    // @ts-expect-error - REVISIT in V5 - https://xstate.js.org/docs/guides/typescript.html#troubleshooting
    event.referenceOrganismSlug,
});

const assignSeekQuerySelectedTerms = assign(
  (context: SeekMachineContext, event: SeekMachineEvent) => {
    if (event.type === 'UPDATE_SEEK_QUERY_SELECTED_TERMS') {
      return { selectedTerms: event.selectedTerms };
    }
    return { selectedTerms: context.selectedTerms };
  },
);

const clearExpressionGeneFilters = assign({
  expressionGeneFilterText: '',
  expressionFilterMatches: [],
});

const clearSelectedDatasets = assign({ selectedDatasets: [] });
const clearSelectedTerms = assign({ selectedTerms: [] });

const goToSeekHome = (): void => {
  history.push(`${SEEK_PAGE.pageRoot}`);
};

const removeError = assign({
  errors: (context: SeekMachineContext, event: SeekMachineEvent) => {
    const { errors } = context;
    // @ts-expect-error - REVISIT in V5 - https://xstate.js.org/docs/guides/typescript.html#troubleshooting
    const { name } = event;
    // Remove the entry `name` from `error` object, if it exists
    const { [name]: _, ...rest } = errors;
    return rest;
  },
});

const resetSeekContextUnload = assign(() => ({ ...getNewDefaultContext() }));

const sendAutomatic = send('AUTOMATIC');

const sendSetError = send(
  (context: SeekMachineContext, event: SeekMachineEvent) => {
    let message = '';
    if (event.type.includes('fetchingSeek')) {
      message = 'There was an error fetching SEEK. Please enter a new query.';
    }
    // @ts-expect-error - REVISIT in V5 - https://xstate.js.org/docs/guides/typescript.html#troubleshooting
    if (event.type === 'error.platform.fetchMultiGeneSearch')
      message = 'There was an error fetching the query genes.';
    // @ts-expect-error - REVISIT in V5 - https://xstate.js.org/docs/guides/typescript.html#troubleshooting
    if (event.type === 'error.platform.fetchSeek')
      message = 'There was an error fetching the Seek results.';
    // @ts-expect-error - REVISIT in V5 - https://xstate.js.org/docs/guides/typescript.html#troubleshooting
    if (event.type === 'error.platform.fetchExpression')
      message = 'There was an error fetching the Seek expression results.';
    return { type: 'SET_ERROR', name: event.type, message };
  },
);

const sendSetSeekResult = send('SET_SEEK_RESULT');

const sendSetHasModSEEKDatasetHash = send('SET_HAS_MODSEEK_DATASET_HASH');

const sendRemoveSeekResult = send('REMOVE_SEEK_RESULT');

const sendSetHasExpressionResult = send('SET_HAS_EXPRESSION_RESULT');

const sendRemoveHasExpressionResult = send('REMOVE_HAS_EXPRESSION_RESULT');

const sendSetHasEnrichmentResult = send('SET_HAS_ENRICHMENT_RESULT');

const sendRemoveHasEnrichmentResult = send('REMOVE_HAS_ENRICHMENT_RESULT');

const sendResetSeek = send('RESET_SEEK');

const sendFetchExpression = send({
  type: 'FETCH_EXPRESSION',
  pageNumber: 1,
  pageYNumber: 1,
});

const sendFetchEnrichment = send('FETCH_ENRICHMENT');

const sendSelectQueryOrganism = send('SELECT_QUERY_ORGANISM');

const sendSubmit = send('SUBMIT');

const setSeekRouteResult = (context: SeekMachineContext) => {
  const locationRegex = `^${SEEK_PAGE.pageRoot}((\\/$)|$)`;
  if (history.location.pathname.match(locationRegex)) {
    history.push(
      `${SEEK_PAGE.pageRoot}/result?bodyTag=${getSeekBodyTag(context)}`,
    );
  }
};

const seekActions = {
  assignEntrezDetailResult,
  assignError,
  assignExpressionGeneFilterText,
  assignPages,
  assignQueryOrganismSlug,
  assignSelectedDatasets,
  assignAvailableDatasets,
  assignModSEEKDatasetOrgHash,
  assignGeneCount,
  assignExpressionResult,
  assignEnrichmentResult,
  assignNewSeekQueryGenes,
  assignSeekResult,
  assignExpressionFilterMatches,
  assignReferenceOrganism,
  assignSeekQuerySelectedTerms,
  clearExpressionGeneFilters,
  clearSelectedDatasets,
  clearSelectedTerms,
  goToSeekHome,
  removeError,
  resetSeekContextUnload,
  sendAutomatic,
  sendSetError,
  sendSetSeekResult,
  sendSetHasModSEEKDatasetHash,
  sendRemoveSeekResult,
  sendSetHasExpressionResult,
  sendRemoveHasExpressionResult,
  sendSetHasEnrichmentResult,
  sendRemoveHasEnrichmentResult,
  sendResetSeek,
  sendFetchExpression,
  sendFetchEnrichment,
  sendSelectQueryOrganism,
  sendSubmit,
  setSeekRouteResult,
};

export default seekActions;
