import { useEffect, useRef } from 'react';
import UAParser from 'ua-parser-js';
import {
  COOKIE_OPTIONS,
  INTERVAL_DISTANCE,
  SEARCH_HUMAN_MULTI_URI,
} from '../settings';

const parser = new UAParser();

// Brandom Morelli's post -- Bionary Search in Javascript. A practical Example
// https://codeburst.io/binary-search-in-javascript-a-practical-example-7fda60ce59a1
export function binarySearch(d, t, s, e) {
  const m = Math.floor((s + e) / 2);
  if (t === d[m].x) return m;
  if (e - 1 === s) return Math.abs(d[s].x - t) > Math.abs(d[e].x - t) ? e : s;
  if (t > d[m].x) return binarySearch(d, t, m, e);
  return binarySearch(d, t, s, m);
}

export function getGenomicInterval(
  location,
  intervalDistance = INTERVAL_DISTANCE,
) {
  const startPosition = Math.max(0, location.position - intervalDistance);
  const endPosition = Math.max(0, location.position + intervalDistance);
  return {
    chromosome: location.chromosome,
    startPosition,
    endPosition,
  };
}

// Use binarySearch but locate the minimum or maximum of repeated values
// placement => min/max
export function binarySearchDuplicates(d, t, s, e, placement) {
  if (placement === 'min') {
    let min = binarySearch(d, t, s, e);
    while (min - 1 >= 0) {
      if (d[min].x === d[min - 1].x) min -= 1;
      else break;
    }
    return min;
  }
  let max = binarySearch(d, t, s, e);
  while (max + 1 < d.length) {
    if (d[max].x === d[max + 1].x) max += 1;
    else break;
  }
  return max;
}

// See Dan Abramov's post -- Making setInterval Declarative...
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (typeof savedCallback.current === 'function') savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return undefined;
  }, [delay]);
}

export function getSlug(title) {
  if (!title) return null;
  let slug = title.replace(/[: ]/g, '-');
  slug = slug.replace(/\+/g, '').toLowerCase();
  return slug;
}

export function isEmptyObject(obj) {
  return (
    Object.getOwnPropertyNames(obj).length === 0 &&
    Object.getOwnPropertySymbols(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
}

export function isSessionStorageEnabled() {
  const testKey = '_localforage_support_test';
  try {
    sessionStorage.setItem(testKey, '1');
    sessionStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
}

export function sortByProperty(obj, property) {
  // convert object into array
  const sortable = [];
  Object.keys(obj).forEach(key => {
    if (property) {
      sortable.push([key, obj[key][property]]);
    } else sortable.push([key, obj[key]]);
    // each item is an array in format [key, value]
  });

  // sort items by value
  sortable.sort((a, b) => b[1] - a[1]); // compare numbers
  return sortable; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
}

export async function doMultiGeneSearch(e, context) {
  const maxURL = 1800; // GET can only handle url length < ~2000 char
  let resp = null;

  const lines = e.trim().split(/[\t\s,\n]+/);
  const qq = [];

  for (let i = 0; i < lines.length; i += 1) {
    let line = lines[i];
    line = line.trim();
    line = line.split('#')[0];
    line = line.replace(/\++/g, '+');

    // Ideally we would also trim any beginning and ending '+' characters but
    // JS doesn't have this function. DRF search endpoint deals with such
    // superfluous delimiters correctly anyway.
    if (line) {
      qq.push(line);
    }
  }

  if (qq.length) {
    let q = qq.join('+');

    // Useful if any line begins or ends with '+' so we end up with duplicate
    // +'s after join.
    q = q.replace(/\++/g, '+');

    if (`${SEARCH_HUMAN_MULTI_URI}${q}`.length < maxURL) {
      const url = `${SEARCH_HUMAN_MULTI_URI}${q}`;
      resp = await context.fetch(url);
    } else {
      const url = `${SEARCH_HUMAN_MULTI_URI}/`;
      resp = await context.fetch(url, {
        method: 'POST',
        body: `{"q": "${q}"}`,
        headers: { 'Content-Type': 'application/json' },
      });
    }

    const json = await resp.json();
    return json;
  }
  return [];
}

export function isObject(n) {
  return Object.prototype.toString.call(n) === '[object Object]';
}

export function hasReservedUrlChars(text) {
  return /[{}~[\]/|\\?=^&%`"':#]+/g.test(text);
}

export function isSupportedUserAgent() {
  const ua = parser.getResult();
  return !(
    ua.browser.name === 'IE' ||
    ua.browser.name === 'IEMobile' ||
    (ua.browser.name === 'Edge' && ua.engine.name === 'EdgeHTML')
  );
}

export function isExPecto(score) {
  return score && score.short_name === 'expecto';
}

// Adapted from: https://www.codetinkerer.com/2019/01/14/cancel-async-requests-pattern.html
if (!global.AbortController) global.AbortController = Object;
let fetchAbortController = new AbortController();

export async function cancelFetchOnReentry(fetch, url) {
  fetchAbortController.abort();
  fetchAbortController = new AbortController();

  const response = await fetch(url, {
    signal: fetchAbortController.signal,
  })
    .then()
    .catch(errors => {
      if (errors.name === 'AbortError') {
        return; // Continuation logic has already been skipped, so return normally
      }
      throw errors;
    });

  return response;
}

// Takes an object and an array of keys to remove from the object,
//  returns a new object without the submitted keys
export const omitKeys = (obj, arr) =>
  Object.keys(obj)
    .filter(k => !arr.includes(k))
    // eslint-disable-next-line no-sequences
    .reduce((acc, key) => ((acc[key] = obj[key]), acc), {});

// //////////////////////////
// Cookie utils
// We needed something that was simple and executable outside of react
export function setCookie(name, value) {
  document.cookie = `${name}=${JSON.stringify(value)}; max-age=${
    COOKIE_OPTIONS.maxAge
  };path=${COOKIE_OPTIONS.path}`;
}

// End cookie utils
// //////////////////////////////////////////////////////

export function getHttps(url) {
  return url.replace(/^http:\/\//i, 'https://');
}

export function getGeneListTSV(geneList) {
  let content = `SYMBOL\tENTREZ\tDESCRIPTION\tALIASES\tXREFS\n`;
  geneList.forEach(gene => {
    content += `${gene.standard_name}\t${gene.entrez}\t${gene.description}\t${gene.aliases}\t${gene.xrefs}\n`;
  });
  return content;
}

export function getUrlFromActivity(activityItem) {
  return activityItem.customUrl !== undefined
    ? `/${activityItem.customUrl}`
    : `/${activityItem.value}`;
}

export const scientificNotation = num => {
  if (num >= 1 && num < 1000) {
    return num.toFixed(2);
  }
  let exponent = Math.floor(Math.log10(Math.abs(num)));
  let mantissa = num / 10 ** exponent;

  while (Math.abs(mantissa) >= 10) {
    mantissa /= 10;
    exponent += 1;
  }

  // Split mantissa into integer and fractional parts
  const [integer, fractional] = mantissa.toString().split('.');

  // Round the fractional part to two digits
  const fractionalPart = (fractional ? `.${fractional}` : '').slice(0, 3);
  mantissa = integer + fractionalPart;

  return `${mantissa}e${exponent}`;
};

export const isUsingDevToolsFromContext = (context, cookieName) => {
  const { isUsingDevTools } = context;
  return typeof isUsingDevTools === 'function'
    ? isUsingDevTools(cookieName)
    : false;
};

export const areEntrezListsEqual = (listA, listB) => {
  const normalizedListA = listA
    .map(item => parseInt(item, 10))
    .sort((a, b) => a - b);
  const normalizedListB = listB
    .map(item => parseInt(item, 10))
    .sort((a, b) => a - b);

  return JSON.stringify(normalizedListA) === JSON.stringify(normalizedListB);
};
