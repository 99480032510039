/* eslint-disable camelcase */

export const organismNames = [
  'Homo sapiens',
  'Caenorhabditis elegans',
  'Danio rerio',
  'Drosophila melanogaster',
  'Mus musculus',
  'Saccharomyces cerevisiae',
] as const;
export const organismSlugs: OrganismSlugString[] = [
  'homo-sapiens',
  'caenorhabditis-elegans',
  'danio-rerio',
  'drosophila-melanogaster',
  'mus-musculus',
  'saccharomyces-cerevisiae',
];

export enum Organisms {
  'homo-sapiens' = 'Homo sapiens',
  'caenorhabditis-elegans' = 'Caenorhabditis elegans',
  'danio-rerio' = 'Danio rerio',
  'drosophila-melanogaster' = 'Drosophila melanogaster',
  'mus-musculus' = 'Mus musculus',
  'saccharomyces-cerevisiae' = 'Saccharomyces cerevisiae',
}

export type OrganismNameString = typeof organismNames[number];
export type OrganismSlugString = keyof typeof Organisms;
export type EmptyObject = Record<string, never>;
export type GeneMatch = {
  aliases: string;
  description: string;
  entrez: number;
  organism: OrganismSlugString;
  score: number;
  standard_name: string;
  xrefs: string;
};
export type GeneDefinition = {
  query: string;
  num_matches: number;
  matches: GeneMatch[];
};
export type GeneMatchLookUp = {
  [key: string]: GeneMatch;
};
export type TissueNetwork = {
  context: {
    term: Term;
    title: string;
    url: string;
  };
  slug: string;
  url: string;
};
export type EntrezLookup = {
  [key: string]: string;
};
export type Term = {
  database: Database;
  label: string;
  value: string;
  datasets?: number;
};
export type Database = {
  annotation_version: string;
  name: string;
  slug: string;
  term_version: string;
  url: string;
};
