import { v5 as uuidv5 } from 'uuid';
import { SeekMachineContext } from '../seekTypes';
import { SEEK_UUID_SEED } from '../../../settings';

export const getSeekBodyTag = (context: SeekMachineContext): string => {
  const { queryEntrez, queryOrganismSlug, selectedTerms } = context;
  const hashedBody = {
    query_entrez: queryEntrez.sort().map(entrez => entrez.toString()),
    query_organism: queryOrganismSlug,
    term_list: selectedTerms.sort((a, b) => a.label.localeCompare(b.label)),
  };
  return uuidv5(JSON.stringify(hashedBody), SEEK_UUID_SEED);
};

export const getNewDefaultContext = (): SeekMachineContext => {
  return {
    availableDatasets: {},
    datasetOrgHash: {},
    errors: {},
    expressionFilterMatches: [],
    expressionGeneDetail: {},
    expressionResults: {
      'homo-sapiens': {},
      'caenorhabditis-elegans': {},
      'danio-rerio': {},
      'drosophila-melanogaster': {},
      'mus-musculus': {},
      'saccharomyces-cerevisiae': {},
    },
    enrichmentResult: {},
    expressionGeneFilterText: '',
    geneCount: 300,
    page: {
      'homo-sapiens': 1,
      'caenorhabditis-elegans': 1,
      'danio-rerio': 1,
      'drosophila-melanogaster': 1,
      'mus-musculus': 1,
      'saccharomyces-cerevisiae': 1,
    },
    pageY: {
      'homo-sapiens': 1,
      'caenorhabditis-elegans': 1,
      'danio-rerio': 1,
      'drosophila-melanogaster': 1,
      'mus-musculus': 1,
      'saccharomyces-cerevisiae': 1,
    },
    queryEntrez: [],
    queryGeneMatches: [],
    queryOrganismSlug: 'homo-sapiens',
    referenceOrganismSlug: 'homo-sapiens',
    seekResult: {},
    selectedDatasets: [],
    selectedTerms: [],
  };
};
