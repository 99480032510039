import {
  CLEAR_COMMUNITY_DATA,
  SET_COMMUNITY_COLUMN_WIDTH,
  SET_COMMUNITY_STATE,
  SET_COMMUNITY_WARNING,
} from '../actions/CommunityActions';

export const defaultCommunityState = {
  genes: [],
  tabKey: 1,
  selectedCommunity: '',
  selectedEdges: [],
  selectedTerm: {},
  warning: null,
  versions: {},
};

function community(state = defaultCommunityState, action) {
  const { type, ...newState } = action;
  switch (type) {
    case CLEAR_COMMUNITY_DATA:
      return Object.assign({}, state, defaultCommunityState, newState);
    case SET_COMMUNITY_COLUMN_WIDTH:
      return Object.assign({}, state, {
        columnWidth: action.columnWidth,
      });
    case SET_COMMUNITY_STATE:
      return Object.assign({}, state, newState);
    case SET_COMMUNITY_WARNING:
      return Object.assign({}, state, {
        warning: action.warning,
      });
    default:
      return state;
  }
}

const communityReducers = { community };

export default communityReducers;
