/*
 * HumanBase API base
 */
export const BASE_URI = '/api/';
export const SEARCH_HUMAN_EXACT_URI = `${BASE_URI}genes/search/exact/?organism=homo-sapiens&q=`;
export const SEARCH_HUMAN_SINGLE_URI = `${BASE_URI}genes/search/partial?organism=homo-sapiens&q=`;
export const SEARCH_HUMAN_MULTI_URI = `${BASE_URI}genes/search/multi?organism=homo-sapiens&q=`;
export const SEARCH_MULTI_URI = `${BASE_URI}genes/search/multi?q=`;

/*
 * Database slugs for specific resources
 */
export const BP_DB = 'gene-ontology-bp';
export const TISSUE_DB = 'brenda-ontology';
export const DISEASE_DB = 'disease-ontology';
export const SFARI_DB = 'sfari-gene';

/*
 * Databases for gene/term annotations
 */
export const ANNOTATION_DATABASES = [BP_DB, SFARI_DB, TISSUE_DB, DISEASE_DB];

/*
 * Databases for displaying term predictions
 */
export const TERM_DATABASES = [
  {
    title: 'Biological process',
    slug: BP_DB,
    params: { score_cutoff: 0.05, max_term_size: 300 },
  },
  {
    title: 'Disease',
    slug: DISEASE_DB,
    params: { score_cutoff: 0.05, max_term_size: 300 },
  },
];

/*
 * Title to be displayed
 */
export const TITLE = 'HumanBase';

/*
 * List of pages
 */
export const EXPECTO_PAGE = { pageName: 'expecto', pageRoot: '/expecto' };
export const EXPECTOSC_PAGE = { pageName: 'expectosc', pageRoot: '/expectosc' };
export const NETWAS_PAGE = { pageName: 'netwas', pageRoot: '/netwas' };
export const MODULE_PAGE = { pageName: 'module', pageRoot: '/module' };
export const NETWORK_PAGE = { pageName: 'gene', pageRoot: '/gene' };
export const DEEPSEA_PAGE = { pageName: 'deepsea', pageRoot: '/deepsea' };
export const SEEK_PAGE = { pageName: 'seek', pageRoot: '/seek' };
export const SEI_PAGE = { pageName: 'sei', pageRoot: '/sei' };

export const ASDBROWSER_PAGE = {
  pageName: 'asdbrowser',
  pageRoot: '/asdbrowser',
};
/*
 * Layout related constants
 */
export const SM_MAX = 991;
export const COMMUNITY_2ND_COL = (1 / 7) * 5;

/*
 * List of example genes to query
 */
export const EXAMPLE_GENES = [
  {
    description: 'Add IL1B to your search query',
    buttonText: 'IL1B',
    pasteText: 'IL1B',
    searchUrl: `${SEARCH_HUMAN_EXACT_URI}il1b`,
    url: '/gene/3553',
  },
  {
    description: 'Add BRCA1 to your search query',
    buttonText: 'BRCA1',
    pasteText: 'BRCA1',
    searchUrl: `${SEARCH_HUMAN_EXACT_URI}brca1`,
    url: '/gene/672',
  },
  {
    description: 'Add PTEN to your search query',
    buttonText: 'PTEN',
    pasteText: 'PTEN',
    searchUrl: `${SEARCH_HUMAN_EXACT_URI}pten`,
    url: '/gene/5728',
  },
];

export const EXAMPLE_SEARCH = [
  {
    description: 'Search for cancer genes',
    buttonText: 'cancer',
    pasteText: 'cancer',
  },
  {
    description: 'Search for autism genes',
    buttonText: 'autism',
    pasteText: 'autism',
  },
];

export const MODULE_EXAMPLE_SEARCH = [
  {
    description: 'Search using 300 genes associated with autism',
    buttonText: '300 autism-associated genes',
    pasteText:
      'ADNP ARID1B DYRK1A ASH1L ASXL3 CHD8 ANK2 CUL3 DSCAM MYT1L ' +
      'KMT5B NAA15 KMT2A GRIN2B KATNAL2 PTEN RELN SCN2A SYNGAP1 SETD5 ' +
      'SHANK3 TBR1 TRIP12 CNTN4 BCKDK CACNA1H ANKRD11 DEAF1 DDX3X BCL11A ' +
      'CIC CNTNAP2 BAZ2B CACNA1D CACNA2D3 CTNND2 CUX1 DIP2C ERBIN MBOAT7 ' +
      'MECP2 FOXP1 NRXN1 MAGEL2 MED13 MED13L GABRB3 KDM5B KMT2C GIGYF2 ' +
      'GRIA1 ILF2 INTS6 IRF2BPL KAT2B KDM6A LEO1 NCKAP1 NLGN3 SHANK2 ' +
      'SMARCC2 PTCHD1 SCN9A SLC6A1 PHF3 RANBP17 RIMS1 SPAST SRCAP SRSF11 ' +
      'TAOK2 TBL1XR1 TCF20 USP7 WDFY3 TRIO UPF3B WAC TNRC6B UBN2 ' +
      'USP15 ANK3 CELF4 CNOT3 CTCF CTNNB1 CTTNBP2 DISC1 AVPR1A CNTN5 ' +
      'CNTN6 ASTN2 DYNC1H1 BTAF1 CC2D1A CYFIP1 EHMT1 CACNB2 ACHE ADA ' +
      'CNKSR2 ELP4 AGAP2 AHDC1 AKAP9 AUTS2 CNTNAP4 AMPD1 AMT ANXA1 ' +
      'APBB1 APH1A ASPM ATP10A CACNA1E CAPRIN1 CCT4 CDC42BPB CEP135 CEP41 ' +
      'CGNL1 CHMP1A CIB2 CLASP1 CUL7 DAPP1 DENR DIP2A DLGAP1 DNMT3A ' +
      'DOCK8 DPP10 DPYSL2 EFR3A ELAVL3 EP400 EMSY ETFB KCNQ2 MACROD2 ' +
      'FBN1 ITGB3 KCNQ3 MBD5 NLGN4X FOXP2 NRXN3 GPHN KAT6A KDM6B ' +
      'MTOR NLGN1 JARID2 NEXMIF HECW2 KCNJ10 KDM5C FAM92B GGNBP2 GIGYF1 ' +
      'GPC4 GRID1 GRIK5 HECTD4 HIVEP3 HMGN1 KIAA1586 KIRREL3 KMT2E LAMB1 ' +
      'LMX1B LZTR1 MFRP MYO5A MYO9B NAV2 NINL NR3C2 OPHN1 SBF1 ' +
      'OXTR SCN1A SCN8A SETBP1 SLC12A5 PRKN SEMA5A STXBP1 SMARCA4 PAH ' +
      'PLCB1 PRICKLE1 RBFOX1 TANC2 SETD2 PHIP PRICKLE2 PRODH ROBO2 NUAK1 ' +
      'OR52M1 OTUD7A P2RX5 P4HA2 PARD3B PAX5 PER2 PHB PHRF1 PLXNA4 ' +
      'PLXNB1 PON1 PREX1 PRKCB PTK7 PYHIN1 RAB2A RAB43 RBM27 SAE1 ' +
      'SHANK1 SHOX SLC35B1 SLC38A10 SLC6A3 SLC7A3 SLC7A5 SMAD4 SPARCL1 STXBP5 ' +
      'TAF6 TRAPPC9 TSC2 ZMYND11 TMLHE UBE3A TBC1D31 TCF7L2 WWOX ZBTB20 ' +
      'ZNF804A TERF2 TET2 TRPC6 TRPM1 UBE3C UBR5 UNC79 USP45 VIL1 ' +
      'ZC3H4 ARNT2 ATP1A3 ATRX CADPS2 CAMK2A CUX2 ADORA2A ARHGEF9 CSMD1 ' +
      'CYP11B1 DIXDC1 ARHGAP32 ASMT CADM1 CD38 EXT1 ADCY5 AFF2 CTNNA3 ' +
      'DDX53 EP300 FABP5 AGBL4 BRCA2 BRD4 CDH11 CMIP DPP6 ESR2 ' +
      'CASK CHD1 ABAT ABCA10 ABCA13 ABCA7 ACE ADK DRD3 ADORA3 ' +
      'ADRB2 AGAP1 AGMO AGO1 AGTR2 BBS4 APBA2 ARHGAP5 ARHGEF10 CNTNAP5 ',
  },
];

export const VCF_EXAMPLE_TEXT =
  'chr5\t134871851\tNEUROG1\tA\tG\n' +
  'chr7\t49813589\tVWC2\tG\tC\n' +
  'chr17\t76352731\tSOCS3\tG\tA\n' +
  'chr19\t59070227\tUBE2M\tC\tA\n' +
  'chr8\t74206149\tRDH10\tC\tG\n' +
  'chr11\t69593819\tFGF4_a\tG\tA\n' +
  'chr3\t12598855\tMKRN2\tG\tA\n' +
  'chr11\t2435681\tTRPM5\tC\tT\n' +
  'chr3\t54158012\tCACNA2D3\tT\tC\n' +
  'chr10\t96305280\tHELLS\tT\tC\n' +
  'chr6\t108879283\tFOXO3\tG\tA\n' +
  'chr19\t1854681\tREXO1\tC\tG';

export const FASTA_EXAMPLE_TEXT = `>known_CEBP_binding_increase-GtoT__chr1_109817091_109818090
GTGCCTCTGGGAGGAGAGGGACTCCTGGGGGGCCTGCCCCTCATACGCCATCACCAAAAGGAAAGGACAAAGCCACACGC
AGCCAGGGCTTCACACCCTTCAGGCTGCACCCGGGCAGGCCTCAGAACGGTGAGGGGCCAGGGCAAAGGGTGTGCCTCGT
CCTGCCCGCACTGCCTCTCCCAGGAACTGGAAAAGCCCTGTCCGGTGAGGGGGCAGAAGGACTCAGCGCCCCTGGACCCC
CAAATGCTGCATGAACACATTTTCAGGGGAGCCTGTGCCCCCAGGCGGGGGTCGGGCAGCCCCAGCCCCTCTCCTTTTCC
TGGACTCTGGCCGTGCGCGGCAGCCCAGGTGTTTGCTCAGTTGCTGACCCAAAAGTGCTTCATTTTTCGTGCCCGCCCCG
CGCCCCGGGCAGGCCAGTCATGTGTTAAGTTGCGCTTCTTTGCTGTGATGTGGGTGGGGGAGGAAGAGTAAACACAGTGC
TGGCTCGGCTGCCCTGAGGTTGCTCAATCAAGCACAGGTTTCAAGTCTGGGTTCTGGTGTCCACTCACCCACCCCACCCC
CCAAAATCAGACAAATGCTACTTTGTCTAACCTGCTGTGGCCTCTGAGACATGTTCTATTTTTAACCCCTTCTTGGAATT
GGCTCTCTTCTTCAAAGGACCAGGTCCTGTTCCTCTTTCTCCCCGACTCCACCCCAGCTCCCTGTGAAGAGAGAGTTAAT
ATATTTGTTTTATTTATTTGCTTTTTGTGTTGGGATGGGTTCGTGTCCAGTCCCGGGGGTCTGATATGGCCATCACAGGC
TGGGTGTTCCCAGCAGCCCTGGCTTGGGGGCTTGACGCCCTTCCCCTTGCCCCAGGCCATCATCTCCCCACCTCTCCTCC
CCTCTCCTCAGTTTTGCCGACTGCTTTTCATCTGAGTCACCATTTACTCCAAGCATGTATTCCAGACTTGTCACTGACTT
TCCTTCTGGAGCAGGTGGCTAGAAAAAGAGGCTGTGGGCA
>known_FOXA2_binding_decrease-ReferenceAllele__chr10_23507864_23508863
CTTCTTTTTATCTCTTAACTAACTTACAATTTCTTACGTGATTTTAAAACTTGTTTTTCTATTTAAAACAACAGGGGCAA
CTGAACTTCACTTTCAAACAATATTTATTTCTATAAATCAGTGCAAAACATACTTATTGAAAATATATCTTGGGTCCAAG
GCTTCAAAGGGTAAAAAGAAAGATTTTAAATTATATCTAATATGTTACAATTGTTCTGTCCTTTAAAAACCTTTTCAGAT
CACCCCCTGGATGATTCTTCCCTAGAAGTCTCAGAGAATTAACAACACAATGTAATCTAGGTTTAAATTTGGGTTTCTCC
TGTGTTTCAGATACTGATGTTTGAGCTTTCTCTTCCTGACAAGCCACTTAAAGAGTCACTGTTACTTTGAGGTTTTATCT
GTAAGATTCGTGTCTTTTGGGCTCATTAAGAACATTTCCAAAGATTACAATGTCAATAGCACCTAATTACTGGACTGTGA
GAAAGGTCTTCTTGAGTACATAAAATCTGTGGCAGTGCACAGTACACAATGGGCAGCTCAGATCCCAAATTTTATCACAA
GTAAGTAGCAAACAAATTAATAATGTTACCTGTGCTCTCTTGGATAATTACTACTGCATAAAAACTGCTTTGAAATGTTG
CAGATAGTATTGTACCTCATTTTTTTAATCCCCTTAGAGTAACAAGGATTTATTTGTCTCAAACTTTCTATGTTGCATGC
ACCACTTGACTTTCTTGTTCTGTTTAGAATTTTTAGAACTTGCAACATAACAAAAAATCATTTTTAACCAGCCTAGGAAG
GACATATCACCTGATGTAACATTATTTTAAATTATATTTTGTATTTTACTTTACTCTTTTCAAAACATATACTGTATGTT
TTGATACTATTGCTAGATTTTATTTTTTACTTATGCCTGGTAGAAAATCAGCTATTAAAGAAGCAGAGGAGGCTGGACAC
AGTGGTTCATGTCTGTAATCGCTAGCACTTTGAAAGAGTA
>known_GATA1_binding_increase-TtoC__chr16_209210_210209
GGGCTTAGACAGAGGAGGGGAGGATTCAGATTTTAAATGGGTTGGCCACTGTAGGTCTATTAACGTGGTGACATTTGAGG
GAGTGGCAATACTAGGGAAGGGGCTTCAGGGGAGTGGCCAGGAGCTAGGGATAGAGGGAGGGAGGACAGGAGGCCTTGTC
TGTCTTTTCCTCCATATGTAAGTTTCAGGAGTGAGTGGGGGGTGTCGAGGGTGCTGTGCTCTCCGGCCTGAGCCTCAGGA
AGGAAGGGCAGTAGTCAGGGATGCCAGGGAAGGACAGTGGAGTAGGCTTTGTGGGGAACTTCACGGTTCCATTGTTGAGA
TGATTTGCTGGAGACACACAGATGAGGACATCAAATACATCCCTGGATCAGGCCCTGGGGCCTGAGTCCGGAAGAGAGGT
CTGTATGGACACACCCATCAATGGGAGCACCAGGACACAGATGGAGGCTAATGTCATGTTGTAGACAGGATGGGTGCTGA
GCTGCCACACCCACATTATCAGAAAATAACAGCACAGGCTTGGGGTGGAGGCGGGACACAAGACTAGCCAGAAGGAGAAA
GAAAGGTGAAAAGCTGTTGGTGCAAGGAAGCTCTTGGTATTTCCAATGGCTTGGGCACAGGCTGTGAGGGTGCCTGGGAC
GGCTTGTGGGGCACAGGCTGCAAGAGGTGCCCAGGACGGCTTGTGGGGCACAGGTTGTGAGAGGTGCCCTGGACGGCTTG
TGGGGCACAGGCTGTGAGAGGTGCCCAGGACGGCTTGTGGGGCACAGGCTGTGAGGGTGCCCGGGACGGCTTGTGGGGCA
CAGGTTGTGAGAGGTGCCCGGGACGGCTTGTGGGGCACAGGTTTCAGAGGTGCCCGGGACGGCTTGTGGGGCACAGGTTG
TGAGAGGTGCCCGGGACGGCTTGTGGGACACAGGTTGTGAGAGGTGCCTGGGACGGCTTGTGGGGCACAGGCTGTGAGGG
TGCCTGGGACGGCTTGTGGGGCACAGGTTGTGAGAGGTGC
>known_FOXA1_binding_increase-CtoT_chr16_52598689_52599688
GGCTCAAGCAGTCCTCCCATCTAGGCTTCCCAAAATGCTGGGATTACAGACATGAGCCACTGCACCCAGCCACAAAGATA
ACCTAAAGATGTGTTTACTTTGACCCAGGCAGTAGTTTAAAAAAGTTTTAATTTGTTGTTCACATTTAAAAACTGGACAA
TTTCTACATAAAAATCTGAATTACTCATGTCTCTTAAAAAAATAACATCTAGCAATGGTAGGCCCACATTCCTTCCTGAA
AATAATTAGCTGGGAAAGAGTAGGGACTGACCCCTTTAGACACGGTATAAATAGCATGGGAGTTGATCAGTAAATATTTG
CTGAATGAAAGAATACATGAATGAAAAGTCAGAGCCCTATAGGTCAGCATGGACGGCGGTAAAGGAACCTGGCTGAGCCT
GAAAGAGAATGTGATCTAAGATTAAATCCAGGATATGCTGGTAAATGTTTAACAGCCAACTCTTTGGGGAGGAAAAAAGT
CCCAATTTGTAGTGTTTGCTGATTATTGTGATGTAAATACTCCCATCATGACCAATTTCAAGCTACCAACATGCTGACAC
TGAACTTGGAGTTGGAAGGAGATGAACAGGCATAATCAGGTCTCGTGAGATGGCCCAAGCCGGCCCCAGCACTCCACTGT
TATATATGAGGCTAGAATTACTACATAACTGGAATAGCAACTTTCTGGACCATATGCCTGGAACACAGCAGGTGCTGAAT
AAATGTTTGTTGATCCAGGAACTGACTGTGTTGAAGCCCACAGATGGGAAATCAGTAGAAGGCAGGTAAGAGTAAAAAGA
AGGGCAGAGAATTGGGGGTACAGACCCCTGAACCATAAGTCAGAGGAATGTTGTACATGTTTTCAGATCCCTCACTGGTC
AAATGAAGGCAAAGGGTTAGATCTCTCCAAATCTTTAGAGGGACATGATGTAACTCCATTAAGTAACTCAGTGATTTTCA
ACATTAAAAAGTGTAATTATCTTTTCAAACTAAATATTAC`;

export const BED_EXAMPLE_TEXT =
  'chr1\t109817090\t109818090\t.\t0\t*\n' +
  'chr10\t23507863\t23508863\t.\t0\t*\n' +
  'chr16\t52598688\t52599688\t.\t0\t*\n' +
  'chr16\t209209\t210209\t.\t0\t*\n' +
  'chr16\t83947129\t83947129\t.\t0\t*\n' +
  'chr16\t85833314\t85833314\t.\t0\t*\n' +
  'chr17\t76352731\t76352731\t.\t0\t*\n' +
  'chr19\t1854681\t1854681\t.\t0\t*\n' +
  'chr19\t55999138\t55999138\t.\t0\t*\n' +
  'chr19\t59070227\t59070227\t.\t0\t*\n';

export const STRANDED_BED_EXAMPLE_TEXT =
  'chr1\t109817090\t109818090\t.\t0\t-\n' +
  'chr10\t23507863\t23508863\t.\t0\t+\n' +
  'chr16\t52598688\t52599688\t.\t0\t-\n' +
  'chr16\t209209\t210209\t.\t0\t-\n' +
  'chr16\t83947129\t83947129\t.\t0\t+\n' +
  'chr16\t85833314\t85833314\t.\t0\t+\n' +
  'chr17\t76352731\t76352731\t.\t0\t+\n' +
  'chr19\t1854681\t1854681\t.\t0\t+\n' +
  'chr19\t55999138\t55999138\t.\t0\t+\n' +
  'chr19\t59070227\t59070227\t.\t0\t+\n';

export const STRANDED_VCF_EXAMPLE_TEXT =
  'chr1\t109817590\t[known_CEBP_binding_increase]\tG\tT\t-\n' +
  'chr10\t23508363\t[known_FOXA2_binding_decrease]\tA\tG\t+\n' +
  'chr16\t52599188\t[known_FOXA1_binding_increase]\tC\tT\t-\n' +
  'chr16\t209709\t[known_GATA1_binding_increase]\tT\tC\t-\n' +
  'chr16\t83947129\tMLYCD\tT\tA\t+\n' +
  'chr16\t85833314\tCOX4I1\tG\tT\t+\n' +
  'chr17\t76352731\tSOCS3\tG\tA\t+\n' +
  'chr19\t1854681\tREXO1\tC\tG\t+\n' +
  'chr19\t55999138\tSSC5D\tG\tA\t+\n' +
  'chr19\t59070227\tUBE2M\tC\tA\t+\n';

export const ANALYSES_META_INFO = {
  expecto: {
    name: 'ExPecto',
    fileFormats: ['vcf'],
    textOverview: `ExPecto leverages deep learning-based sequence models trained on chromatin profiling data,
     and integrated with spatial transformation and regularized linear models. This framework does not use any
     variant information, enabling prediction of the expression effect for any variant, even those that are rare
     or have never been previously observed.`,
    helpUrl: 'https://humanbase.readthedocs.io/en/latest/expecto.html',
    imageUrl: null,
    defaultModelName: 'beluga',
    genomes: ['hg19'],
  },
  deepsea: {
    name: 'DeepSEA',
    fileFormats: ['vcf', 'bed', 'fasta'],
    textOverview: `DeepSEA is a deep learning framework that predicts genomic variant effects on a wide range
     of regulatory features with single nucleotide sensitivity: transcription factors binding, DNase I hypersensitive
     sites, and histone marks in multiple human cell types.`,
    helpUrl: 'https://humanbase.readthedocs.io/en/latest/deepsea.html',
    imageUrl: `/deepsea/deepsea_logo.svg`,
    defaultModelName: 'sei',
    genomes: ['hg19', 'hg38'],
  },
  insilico: {
    name: 'In Silico Mutagenesis',
    fileFormats: ['fasta'],
    textOverview: `Perform “In silico saturated mutagenesis” analysis to discover informative regulatory
     features within any sequence. Specifically, it performs computational mutation scanning by accessing the
      predicted regulatory effect of mutating every base of the input sequence.`,
    helpUrl:
      'https://humanbase.readthedocs.io/en/latest/beluga.html#in-silico-mutagenesis',
    imageUrl: null,
    defaultModelName: 'beluga',
  },
  sei: {
    name: 'Sei',
    fileFormats: ['vcf', 'bed', 'fasta'],
    textOverview: `Sei is a deep-learning-based framework for systematically predicting sequence regulatory activities and applying sequence information to understand human genetics data.`,
    helpUrl: 'https://humanbase.readthedocs.io/en/latest/sei.html',
    defaultModelName: 'sei',
    genomes: ['hg19', 'hg38'],
  },
};

export const EXPECTO_EXAMPLE_GENES = [
  {
    description: 'Search for ExPecto variant effect predictions for IRGM',
    buttonText: 'IRGM',
    pasteText: 'IRGM',
    searchUrl: `${SEARCH_HUMAN_EXACT_URI}irgm`,
    url: '/gene/345611',
  },
  {
    description: 'Search for ExPecto variant effect predictions for CCR1',
    buttonText: 'CCR1',
    pasteText: 'CCR1',
    searchUrl: `${SEARCH_HUMAN_EXACT_URI}ccr1`,
    url: '/gene/1230',
  },
];

export const EXPECTO_EXAMPLE_RSID_FILTER = [
  {
    description: 'Filter for enriched rsIDs related to IRGM',
    buttonText: 'IRGM',
    pasteText: 'IRGM',
    searchUrl: `rsid__isempty=False&page=1&gene__standard_name__istartswith=IRGM`,
  },
  {
    description: 'Find ExPecto predictions by rsID',
    buttonText: 'rs531708574',
    pasteText: 'rs531708574',
    searchUrl: `rsid__isempty=False&page=1&rsid__startswith=rs531708574`,
  },
  {
    description: 'Search for rsIDs with ExPecto predictions by genome position',
    buttonText: 'chr1:877047',
    pasteText: 'chr1:877047',
    searchUrl: `rsid__isempty=False&page=1&chromosome=chr1&position__startswith=877047`,
  },
];

export const EXPECTO_EXAMPLE_VARIANTS = [
  {
    description: 'Search for rsIDs with ExPecto predictions for IRGM',
    buttonText: 'variants',
    pasteText: STRANDED_VCF_EXAMPLE_TEXT,
  },
];

export const EXPECTO_CONFIG = {
  name: 'ExPecto',
  database: 'expecto-tissues',
  overviewLabel: 'Tissue System',
  tissueLabel: 'Tissue',
  range: [-1.0, -0, 1.0],
};

export const EXPECTOSC_EXAMPLE_GENES = [
  {
    description: 'Search for ExPectoSC variant effect predictions for PTEN',
    buttonText: 'PTEN',
    pasteText: 'PTEN',
    searchUrl: `${BASE_URI}genes/search/exact/?q=pten`,
    url: '/gene/5728',
  },
];
export const EXPECTOSC_CONFIG = {
  name: 'ExPectoSC',
  database: 'clever-tissues',
  overviewLabel: 'Organ',
  tissueLabel: 'Cell-type',
  range: [-100.0, 0, 100.0],
};

export const DEEPSEA_ANALYSES = {
  deepsea: 'deepsea',
  insilico: 'insilico',
  expecto: 'expecto',
  sei: 'sei',
};

export const SEEK_EXAMPLE_GENES = [
  {
    description:
      'Search Hedgehog (Hh) pathway using transcription factors and receptor protein',
    buttonText: 'GLI1 GLI2 PTCH1',
    pasteText: 'GLI1 GLI2 PTCH1',
  },
];

export const MISSING_POSITION_INTERVAL = 50000;
export const INTERVAL_DISTANCE = 50000;

export const { STATIC_HOST_URL } = process.env;
export const { MAILCHIMP_URL } = process.env;
export const { MAILCHIMP_LID } = process.env;
export const { MAILCHIMP_UUID } = process.env;
export const MAILCHIMP_SUBSCRIBE_URL = `https://${MAILCHIMP_URL}/subscribe/post?u=${MAILCHIMP_UUID}&id=${MAILCHIMP_LID}`;

export const DOC_URL = 'https://humanbase.readthedocs.io/';

export const NETWORK_MAX_THRESHOLD = 30;
export const COMMUNITY_MIN_THRESHOLD = 20;
export const MAX_GENE_QUERY_SIZE = 3500;
export const MAX_RECOMMENDED_GENE_QUERY_SIZE = 2550;

export const DEEPSEA_MODELS = [
  {
    name: 'Sei',
    label: 'Sei (DNA model)',
    vizInputTypes: ['vcf'],
    stranded: false,
  },
  {
    name: 'Beluga',
    label: 'Beluga (DNA model - 2019)',
    vizInputTypes: ['vcf', 'bed'],
    stranded: false,
  },
  {
    name: 'SeqWeaver',
    label: 'Seqweaver (RNA model)',
    vizInputTypes: ['vcf', 'bed'],
    stranded: true,
  },
];

export const IS_LOCAL_XSTATE_VIZ = 'IS_LOCAL_XSTATE_VIZ';
export const DEBUG_XSTATE_FMD = 'DEBUG_XSTATE_FMD';
export const DEBUG_XSTATE_GENE_SEARCH_RESULT_TABLE =
  'DEBUG_XSTATE_GENE_SEARCH_RESULT_TABLE';
export const DEBUG_XSTATE_PASTEBOX = 'DEBUG_XSTATE_PASTEBOX';
export const DEBUG_XSTATE_SEEK_DATASET_SELECT_FILTER =
  'DEBUG_XSTATE_SEEK_DATASET_SELECT_FILTER';
export const DEBUG_XSTATE_SEEK_PAGE = 'DEBUG_XSTATE_SEEK_PAGE';
export const DEBUG_XSTATE_TERM_SEARCHABLE_MULTISELECT =
  'DEBUG_XSTATE_TERM_SEARCHABLE_MULTISELECT';
export const DEBUG_XSTATE_WHATS_NEW = 'DEBUG_XSTATE_WHATS_NEW';
export const XSTATE_DEBUG_COOKIES = [
  DEBUG_XSTATE_FMD,
  DEBUG_XSTATE_GENE_SEARCH_RESULT_TABLE,
  DEBUG_XSTATE_PASTEBOX,
  DEBUG_XSTATE_SEEK_PAGE,
  DEBUG_XSTATE_SEEK_DATASET_SELECT_FILTER,
  DEBUG_XSTATE_TERM_SEARCHABLE_MULTISELECT,
  DEBUG_XSTATE_WHATS_NEW,
];

export const DEEPSEA_COOKIE_NAME = 'savedDeepSEAJobs';
export const GENOME_ASSEMBLY_COOKIE = 'genomeAssembly';
export const COOKIE_OPTIONS = {
  path: '/',
  maxAge: 60 * 60 * 24 * 365, // year
};

// Update the version in the constant HIDE_LATEST_BANNER_COOKIE to
//  re-enable both the banner and notification dot for the next deployment.
export const HIDE_LATEST_BANNER_COOKIE = 'whatsNewBannerCookie-2024-10-17';
export const HIDE_LATEST_NOTIFICATION_COOKIE = `${HIDE_LATEST_BANNER_COOKIE}-notification`;

const CHROMOSOMES = ['chrX', 'chrY'];
for (let i = 1; i <= 22; i += 1) {
  CHROMOSOMES.push(`chr${i.toString()}`);
}

export { CHROMOSOMES };

export const SEQ_CAT_COLORS = {
  E: '#984ef3',
  CTCF: '#fdb462',
  PC: '#8abad4',
  'P ': '#ef3b2c',
  HET: '#662506',
  TN: '#fb9a99',
  TF: '#386cb0',
  L: '#C0C0C0',
};

export const SEQ_CAT_NAMES = {
  E: 'Enhancer',
  CTCF: 'CTCF',
  PC: 'Polycomb',
  'P ': 'Promoter',
  HET: 'Heterochomatin',
  TN: 'Transcription',
  TF: 'Transcription factor',
  L: 'Low signal',
};

export const AWS_URL = 'https://humanbase.s3.amazonaws.com';

export const MAX_COOKIE_AGE = 60 * 60 * 24 * 365; // a year

export const SEEK_URI = `${BASE_URI}seek/`;
export const SEEK_UUID_SEED = '91cf34a0-32d1-46c1-9f05-fb1e6923fa8c';
