/* eslint-disable camelcase */
import { createMachine, MachineConfig, MachineOptions } from 'xstate';
import { SeekMachineContext, SeekMachineEvent } from '../seekTypes';
import { EmptyObject } from '../../shared/sharedTypes';
import { getNewDefaultContext } from './seekUtils';
import seekActions from './seekActions';
import seekServices from './seekServices';
import seekGuards from './seekGuards';

// TODO - gracefully fail with specific message when SeekRPC is down

const setExpressionGeneFilterText = {
  SET_EXPRESSION_GENE_FILTER_TEXT: {
    actions: ['assignExpressionGeneFilterText'],
    target: 'idle',
  },
};

export interface SeekStateSchema {
  states: {
    queryOrganismSelectMethod: {
      states: {
        automatic: EmptyObject;
        manual: EmptyObject;
      };
    };
    hasError: {
      states: {
        no: EmptyObject;
        yes: EmptyObject;
      };
    };
    seekQueryManager: {
      states: {
        seekQuery: {
          states: {
            idle: EmptyObject;
            fetchingSeek: EmptyObject;
          };
        };
        geneSearchManager: {
          states: {
            idle: EmptyObject;
          };
        };
      };
    };
    enrichmentQueryManager: {
      states: {
        idle: EmptyObject;
        fetchingEnrichment: EmptyObject;
      };
    };
    expressionQueryManager: {
      states: {
        expressionQuery: {
          states: {
            idle: EmptyObject;
            fetchingExpression: EmptyObject;
            fetchingEntrezDetail: EmptyObject;
          };
        };
        modSEEKDatasetManager: {
          states: {
            hasModSEEKDatasetHash: {
              states: {
                no: EmptyObject;
                yes: EmptyObject;
              };
            };
            modSEEKDatasetUpdates: {
              states: {
                idle: EmptyObject;
                retryFetchModSEEKDatasetHash: EmptyObject;
                fetchingModSEEKDatasetHash: EmptyObject;
              };
            };
          };
        };
      };
    };
    seekResultManager: {
      states: {
        hasSeekResult: {
          states: {
            no: EmptyObject;
            yes: EmptyObject;
          };
        };
        hasExpressionResult: {
          states: {
            no: EmptyObject;
            yes: EmptyObject;
          };
        };
        hasEnrichmentResult: {
          states: {
            no: EmptyObject;
            yes: EmptyObject;
          };
        };
      };
    };
  };
}

export const seekMachineConfig: MachineConfig<
  SeekMachineContext,
  SeekStateSchema,
  SeekMachineEvent
> = {
  schema: {
    context: {} as SeekMachineContext,
    events: {} as SeekMachineEvent,
  },
  id: 'seek',
  type: 'parallel',
  context: { ...getNewDefaultContext() },
  states: {
    queryOrganismSelectMethod: {
      initial: 'automatic',
      states: {
        automatic: {
          on: {
            MANUAL: {
              actions: [
                'sendSelectQueryOrganism',
                'assignQueryOrganismSlug',
                'clearSelectedTerms',
              ],
              target: 'manual',
            },
          },
        },
        manual: {
          on: {
            AUTOMATIC: { target: 'automatic' },
            MANUAL: {
              actions: [
                'sendSelectQueryOrganism',
                'assignQueryOrganismSlug',
                'clearSelectedTerms',
              ],
              target: 'manual',
            },
          },
        },
      },
    },
    hasError: {
      initial: 'no',
      states: {
        no: {
          on: {
            SET_ERROR: {
              target: 'yes',
              actions: ['assignError'],
            },
          },
        },
        yes: {
          on: {
            REMOVE_ERROR: {
              target: 'no',
              actions: ['removeError'],
            },
          },
        },
      },
    },
    seekQueryManager: {
      type: 'parallel',
      states: {
        seekQuery: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                SUBMIT: {
                  actions: ['sendRemoveSeekResult'],
                  target: 'fetchingSeek',
                },
                UPDATE_SEEK_QUERY_SELECTED_TERMS: {
                  actions: ['assignSeekQuerySelectedTerms'],
                  target: 'idle',
                },
              },
            },
            fetchingSeek: {
              invoke: {
                src: 'fetchSeek',
                onDone: {
                  actions: ['assignSeekResult', 'sendSetSeekResult'],
                  target: 'idle',
                },
                onError: {
                  actions: ['goToSeekHome', 'sendSetError'],
                  target: 'idle',
                },
              },
            },
          },
        },
        geneSearchManager: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                SET_NEW_SEEK_QUERY_GENES: {
                  actions: [
                    'assignNewSeekQueryGenes',
                    'assignQueryOrganismSlug',
                  ],
                  target: 'idle',
                },
              },
            },
          },
        },
      },
    },
    enrichmentQueryManager: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            FETCH_ENRICHMENT: {
              target: 'fetchingEnrichment',
            },
          },
        },
        fetchingEnrichment: {
          invoke: {
            src: 'fetchEnrichment',
            onDone: {
              actions: ['assignEnrichmentResult', 'sendSetHasEnrichmentResult'],
              target: 'idle',
            },
            onError: {
              actions: 'sendSetError',
              target: 'idle',
            },
          },
        },
      },
    },
    expressionQueryManager: {
      type: 'parallel',
      states: {
        modSEEKDatasetManager: {
          type: 'parallel',
          states: {
            hasModSEEKDatasetHash: {
              initial: 'no',
              states: {
                no: {
                  on: { SET_HAS_MODSEEK_DATASET_HASH: { target: 'yes' } },
                },
                yes: {
                  on: { RESET_SEEK: { target: 'no' } },
                  entry: ['assignAvailableDatasets'],
                },
              },
            },
            modSEEKDatasetUpdates: {
              initial: 'idle',
              states: {
                idle: {
                  on: {
                    SELECT_REFERENCE_ORGANISM: {
                      actions: [
                        'assignAvailableDatasets',
                        'clearSelectedDatasets',
                      ],
                      target: 'idle',
                    },
                    UPDATE_SELECTED_DATASETS: {
                      actions: [
                        'assignSelectedDatasets',
                        'sendFetchExpression',
                      ],
                      target: 'idle',
                    },
                    RESET_SEEK: {
                      actions: [
                        'clearSelectedDatasets',
                        'clearExpressionGeneFilters',
                      ],
                      target: 'fetchingModSEEKDatasetHash',
                    },
                  },
                },
                retryFetchModSEEKDatasetHash: {
                  after: {
                    10000: { target: 'fetchingModSEEKDatasetHash' },
                  },
                },
                fetchingModSEEKDatasetHash: {
                  invoke: {
                    src: 'fetchDatasets',
                    onDone: {
                      target: 'idle',
                      actions: [
                        'assignModSEEKDatasetOrgHash',
                        'sendSetHasModSEEKDatasetHash',
                      ],
                    },
                    onError: {
                      target: 'retryFetchModSEEKDatasetHash',
                    },
                  },
                },
              },
            },
          },
        },
        expressionQuery: {
          initial: 'idle',
          on: {
            UPDATE_SEEK_FMD_GENE_COUNT: {
              actions: ['assignGeneCount'],
            },
          },
          states: {
            idle: {
              on: {
                FETCH_EXPRESSION: [
                  {
                    cond: 'needsNewExpressionResult',
                    actions: ['assignPages'],
                    target: 'fetchingExpression',
                  },
                  {
                    actions: ['assignPages'],
                    target: 'idle',
                  },
                ],
                ...setExpressionGeneFilterText,
                NEW_EXPRESSION_GENE_FILTER_MATCHES: [
                  {
                    cond: 'hasDifferentFilterContents',
                    actions: ['assignExpressionFilterMatches'],
                    target: 'fetchingExpression',
                  },
                  {
                    target: 'idle',
                  },
                ],
                SELECT_REFERENCE_ORGANISM: [
                  {
                    cond: 'needsNewExpressionResult',
                    actions: [
                      'assignReferenceOrganism',
                      'clearExpressionGeneFilters',
                    ],
                    target: 'fetchingExpression',
                  },
                  {
                    actions: ['assignReferenceOrganism'],
                    target: 'idle',
                  },
                ],
              },
            },
            fetchingExpression: {
              entry: ['sendRemoveHasExpressionResult'],
              on: {
                ...setExpressionGeneFilterText,
              },
              invoke: {
                src: 'fetchExpression',
                onDone: {
                  actions: [
                    'assignExpressionResult',
                    'sendSetHasExpressionResult',
                  ],
                  target: 'fetchingEntrezDetail',
                },
                onError: {
                  actions: 'sendSetError',
                  target: 'idle',
                },
              },
            },
            fetchingEntrezDetail: {
              on: {
                ...setExpressionGeneFilterText,
              },
              invoke: {
                src: 'fetchEntrezDetail',
                onDone: {
                  actions: ['assignEntrezDetailResult'],
                  target: 'idle',
                },
                onError: {
                  actions: 'sendSetError',
                  target: 'idle',
                },
              },
            },
          },
        },
      },
    },
    seekResultManager: {
      type: 'parallel',
      on: {
        RESET_SEEK: {
          actions: ['sendRemoveSeekResult'],
          target: 'seekResultManager',
        },
      },
      states: {
        hasSeekResult: {
          initial: 'no',
          states: {
            no: {
              entry: [
                'resetSeekContextUnload',
                'sendRemoveHasExpressionResult',
                'sendRemoveHasEnrichmentResult',
              ],
              on: { SET_SEEK_RESULT: { target: 'yes' } },
            },
            yes: {
              entry: [
                'setSeekRouteResult',
                'sendFetchExpression',
                'sendFetchEnrichment',
                'assignAvailableDatasets',
              ],
              on: {
                REMOVE_SEEK_RESULT: {
                  target: 'no',
                },
              },
            },
          },
        },
        hasExpressionResult: {
          initial: 'no',
          states: {
            no: {
              on: { SET_HAS_EXPRESSION_RESULT: { target: 'yes' } },
            },
            yes: {
              on: { REMOVE_HAS_EXPRESSION_RESULT: { target: 'no' } },
            },
          },
        },
        hasEnrichmentResult: {
          initial: 'no',
          states: {
            no: {
              on: { SET_HAS_ENRICHMENT_RESULT: { target: 'yes' } },
            },
            yes: {
              on: { REMOVE_HAS_ENRICHMENT_RESULT: { target: 'no' } },
            },
          },
        },
      },
    },
  },
};

export const seekMachineOptions: MachineOptions<
  SeekMachineContext,
  SeekMachineEvent
> = {
  activities: {},
  delays: {},
  services: seekServices,
  guards: seekGuards,
  // @ts-expect-error - There are unresolved type errors in `seekActions`
  actions: seekActions,
};

const seekMachine = createMachine(seekMachineConfig, seekMachineOptions);
export default seekMachine;
