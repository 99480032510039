import { getGenomicInterval } from '../core/util';
import { INTERVAL_DISTANCE } from '../settings';
/*
 * Action types
 */
export const UPDATE_GENOMIC_RANGE = 'UPDATE_GENOMIC_RANGE';
export const SET_SELECTED_VARIANT = 'SET_SELECTED_VARIANT';
export const SET_SELECTED_VARIANT_AND_UPDATE_RANGE =
  'SET_SELECTED_VARIANT_AND_UPDATE_RANGE';
export const SET_GENOME_BROWSER_WARNING = 'SET_GENOME_BROWSER_WARNING';

/*
 * Action creators for updating the genomic range
 */
export function updateGenomicRange(chromosome, startPosition, endPosition) {
  return { type: UPDATE_GENOMIC_RANGE, chromosome, startPosition, endPosition };
}

export function updateGenomicRangeWithPosition(chromosome, position) {
  return {
    type: UPDATE_GENOMIC_RANGE,
    ...getGenomicInterval({ chromosome, position }),
  };
}

export function setGenomeBrowserWarning(warning) {
  return {
    type: SET_GENOME_BROWSER_WARNING,
    warning,
  };
}

export function setSelectedVariant(variant) {
  return { type: SET_SELECTED_VARIANT, variant };
}

export function setSelectedVariantAndUpdateRange(variant, genomeRange) {
  return {
    type: SET_SELECTED_VARIANT_AND_UPDATE_RANGE,
    variant,
    ...getGenomicInterval(
      variant,
      genomeRange
        ? Math.ceil(
            (genomeRange.endPosition - genomeRange.startPosition + 1) / 2,
          )
        : INTERVAL_DISTANCE,
    ),
  };
}
