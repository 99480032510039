const routes = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '',
      load: () => import(/* webpackChunkName: 'home' */ './home'),
    },
    {
      path: [
        '/gene',
        '/gene/:ids([+0-9]+)',
        '/gene/:ids([+0-9]+)/:integration([a-z0-9\\-]+)',
      ],
      load: () => import(/* webpackChunkName: 'gene' */ './gene'),
    },
    {
      path: ['/module/overview', '/module/detail', '/module/term', '/module'],
      load: () => import(/* webpackChunkName: 'module' */ './module'),
    },
    {
      path: ['/netwas', '/netwas/:id'],
      load: () => import(/* webpackChunkName: 'netwas' */ './netwas'),
    },
    {
      path: '/download',
      load: () => import(/* webpackChunkName: 'download' */ './download'),
    },
    {
      path: '/studies',
      load: () => import(/* webpackChunkName: 'studies' */ './studies'),
    },
    {
      path: '/data',
      load: () => import(/* webpackChunkName: 'data' */ './data'),
    },
    {
      path: '/about',
      load: () => import(/* webpackChunkName: 'about' */ './about'),
    },
    {
      path: '/whats-new',
      load: () => import(/* webpackChunkName: 'whats-new' */ './whats-new'),
    },
    {
      path: [
        '/expecto',
        '/expecto/about',
        '/expecto/gene/:id/:targetPosition',
        '/expecto/:position',
        '/expecto/gene/:id',
      ],
      load: () => import(/* webpackChunkName: 'expecto' */ './expecto'),
    },
    {
      path: [
        '/dis_asd',
        '/asdbrowser',
        '/asdbrowser/about',
        '/asdbrowser/:genomePosition',
      ],
      load: () => import(/* webpackChunkName: 'asd' */ './asd'),
    },
    {
      path: ['/deepsea', '/deepsea/jobs/:id'],
      load: () => import(/* webpackChunkName: 'deepsea' */ './deepsea'),
    },
    {
      path: ['/seek', '/seek/result'],
      load: () => import(/* webpackChunkName: 'seek' */ './seek'),
    },
    {
      path: '/genome',
      load: () => import(/* webpackChunkName: 'genome' */ './genome'),
    },
    {
      path: ['/seqweaver', '/seqweaver/about'],
      load: () => import(/* webpackChunkName: 'seqweaver' */ './seqweaver'),
    },
    {
      path: [
        '/expectosc',
        '/clever',
        '/expectosc/gene/:id',
        '/clever/gene/:id',
      ],
      load: () => import(/* webpackChunkName: 'expectosc' */ './expectosc'),
    },
    {
      path: '/covid19',
      load: () => import(/* webpackChunkName: 'covid19' */ './covid19'),
    },
    {
      path: '/covid-kidney',
      load: () =>
        import(/* webpackChunkName: 'covid-kidney' */ './covid-kidney'),
    },
    {
      path: ['/sei', '/sei-hgmd', '/sei-hgmd/about'],
      load: () => import(/* webpackChunkName: 'sei' */ './sei'),
    },
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled Page'}`;
    route.description =
      route.description ||
      'Data-driven predictions of gene expression, function, regulation and interactions in human';
    if (!route.context) route.context = { pageName: 'gene', pageRoot: '/' };
    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    // eslint-disable-next-line global-require
    action: require('./error').default,
  });
}

export default routes;
