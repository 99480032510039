import { SET_GENES } from '../actions/actions';

function geneSelection(state = [], action) {
  if (action.id) return state;

  switch (action.type) {
    case SET_GENES:
      return action.genes;
    default:
      return state;
  }
}

const GeneReducers = { geneSelection };

export default GeneReducers;
