import { BASE_URI } from '../settings';

/*
 * DeepSEA action types
 */
export const RECEIVE_DEEPSEA_FEATURES = 'RECEIVE_DEEPSEA_FEATURES';
export const RECEIVE_DEEPSEA_MODELS = 'RECEIVE_DEEPSEA_MODELS';
export const RECEIVE_DEEPSEA_FEATURE_PREDICTIONS =
  'RECEIVE_DEEPSEA_FEATURE_PREDICTIONS';
export const RECEIVE_DEEPSEA_FEATURES_AND_PREDICTIONS =
  'RECEIVE_DEEPSEA_FEATURES_AND_PREDICTIONS';

export const RECEIVE_DEEPSEA_VARIANT_SCORES = 'RECEIVE_DEEPSEA_VARIANT_SCORES';

export const RECEIVE_DEEPSEA_JOB = 'RECEIVE_DEEPSEA_JOB';
export const RECEIVE_DEEPSEA_JOB_FEATURE_PREDICTIONS =
  'RECEIVE_DEEPSEA_JOB_FEATURE_PREDICTIONS';

export const RECEIVE_DEEPSEA_SCORE_LIST = 'RECEIVE_DEEPSEA_SCORE_LIST';

export const SET_SELECTED_DEEPSEA_JOB = 'SET_SELECTED_DEEPSEA_JOB';
export const SET_DEEPSEA_JOB_VALIDATION_ERRORS =
  'SET_DEEPSEA_JOB_VALIDATION_ERRORS';
export const SET_INSILICO_FEATURE_DATA = 'SET_INSILICO_FEATURE_DATA';
export const SET_SELECTED_INSILICO_DATA = 'SET_SELECTED_INSILICO_DATA';
export const SET_SELECTED_DEEPSEA_VARIANT_SCORE_TYPE =
  'SET_SELECTED_DEEPSEA_VARIANT_SCORE_TYPE';
export const SET_SELECTED_DEEPSEA_FEATURE_SCORE_TYPE =
  'SET_SELECTED_DEEPSEA_FEATURE_SCORE_TYPE';
export const REQUEST_INSILICO_FEATURE_DATA = 'REQUEST_INSILICO_FEATURE_DATA';
export const REQUEST_DEEPSEA_FEATURE_PREDICTIONS =
  'REQUEST_DEEPSEA_FEATURE_PREDICTIONS';
export const RESET_DEEPSEA_STATE = 'RESET_DEEPSEA_STATE';
export const SET_DEEPSEA_JOBS = 'SET_DEEPSEA_JOBS';

/*
 * Insilico action creators
 */
export function setSelectedInsilicoData(formData) {
  const { sequence_name: sequenceName, feature_name: featureName } = formData;
  return {
    type: SET_SELECTED_INSILICO_DATA,
    sequenceName,
    featureName,
  };
}

export function setInsilicoFeatureData(formData, featureData) {
  const { sequence_name: sequenceName, feature_name: featureName } = formData;
  const insilicoData = { featureName, sequenceName, featureData };
  return {
    type: SET_INSILICO_FEATURE_DATA,
    ...insilicoData,
  };
}

export function requestInsilicoFeatureData() {
  return {
    type: REQUEST_INSILICO_FEATURE_DATA,
    isFetching: true,
  };
}

export function requestDeepSEAFeaturePredictions() {
  return {
    type: REQUEST_DEEPSEA_FEATURE_PREDICTIONS,
    isFetchingFeaturePredictions: true,
  };
}

export function fetchInsilicoData(jobId, dsFormData) {
  return async (dispatch, getState, { fetch }) => {
    dispatch(requestInsilicoFeatureData());
    const qs = new URLSearchParams(dsFormData);
    const url = `${BASE_URI}deepsea/jobs/${jobId}/in_silico_mutagenesis/?${qs}`;
    const response = await fetch(url);
    const featureData = await response.json();
    dispatch(setInsilicoFeatureData(dsFormData, featureData));
    dispatch(setSelectedInsilicoData(dsFormData));
  };
}

/*
 * DeepSEA Job action creators
 */
export function setSelectedDeepSEAJob(job) {
  return {
    type: SET_SELECTED_DEEPSEA_JOB,
    job,
  };
}

export function receieveDeepSEAJob(job) {
  return {
    type: RECEIVE_DEEPSEA_JOB,
    job,
  };
}

export function setDeepSEAJobValidationErrors(errors) {
  return {
    type: SET_DEEPSEA_JOB_VALIDATION_ERRORS,
    errors,
  };
}

export function fetchNewDeepSEAJob(dsFormData) {
  return async (dispatch, getState, { fetch }) => {
    const formData = new FormData();
    Object.keys(dsFormData).forEach(key => {
      formData.append(key, dsFormData[key]);
    });
    const url = `${BASE_URI}deepsea/jobs/`;
    let json = {};
    let jsonResponse = {};

    await fetch(url, {
      method: 'POST',
      body: formData,
    })
      .then(async response => {
        if (response.status >= 400 && response.statusText !== 'Bad Request') {
          json.statusError = response.statusText;
        }
        try {
          jsonResponse = await response.json();
        } catch (e) {
          // If there is no JSON response then it will throw a JSON SyntaxError,
          //  but at times there are 4XX errors which also have a JSON response
          //  and at other times a 4XX which will not.
          // eslint-disable-next-line prettier/prettier
          if (
            !(
              e instanceof SyntaxError && e.message.includes('Unexpected token')
            )
          )
            throw e;
        }
      })
      .catch(err => {
        json.fetchError = 'There was an unknown error creating the job.';
        throw err;
      });

    json = Object.assign({}, json, jsonResponse);

    if (json.non_field_errors || json.statusError || json.fetchError) {
      dispatch(setDeepSEAJobValidationErrors(json));
    } else {
      const job = {};
      job[json.job_id] = json;
      dispatch(receieveDeepSEAJob(job));
    }
  };
}

export function fetchDeepSEAJobStatus(jobId) {
  const job = {};
  return async (dispatch, getState, { fetch }) => {
    const url = `${BASE_URI}deepsea/jobs/${jobId}/`;
    const response = await fetch(url);
    if (response.status === 404) {
      job[jobId] = { status: 'error: job not found' };
    } else if (response.status !== 200) {
      job[jobId] = { status: 'error: unable to retrieve job' };
    } else {
      job[jobId] = await response.json();
    }
    dispatch(receieveDeepSEAJob(job));
  };
}

/*
 * DeepSEA action creators
 */
export function setSelectedVariantScoreType(selectedVariantScoreType) {
  return {
    type: SET_SELECTED_DEEPSEA_VARIANT_SCORE_TYPE,
    selectedVariantScoreType,
  };
}

export function setSelectedFeatureScoreType(selectedFeatureScoreType) {
  return {
    type: SET_SELECTED_DEEPSEA_FEATURE_SCORE_TYPE,
    selectedFeatureScoreType,
  };
}

export function receiveDeepSEAFeatures(features) {
  return {
    type: RECEIVE_DEEPSEA_FEATURES,
    features,
  };
}

export function receiveDeepSEAModels(models) {
  return {
    type: RECEIVE_DEEPSEA_MODELS,
    models,
  };
}

export function receiveDeepSEAFeaturePredictions(featurePredictions) {
  return {
    type: RECEIVE_DEEPSEA_FEATURE_PREDICTIONS,
    featurePredictions,
    isFetchingFeaturePredictions: false,
  };
}

export function receiveDeepSEAFeaturesAndPredictions(
  features,
  featurePredictions,
  featureQuerySuccess,
) {
  return {
    type: RECEIVE_DEEPSEA_FEATURES_AND_PREDICTIONS,
    features,
    featurePredictions,
    featureQuerySuccess,
    isFetchingFeaturePredictions: false,
  };
}

export function receiveDeepSEAVariantScores(variantScores) {
  return {
    type: RECEIVE_DEEPSEA_VARIANT_SCORES,
    variantScores,
  };
}

export function receiveDeepSEAJobFeaturePredictions(featurePredictions) {
  return {
    type: RECEIVE_DEEPSEA_JOB_FEATURE_PREDICTIONS,
    featurePredictions,
    isFetchingFeaturePredictions: false,
  };
}

export function receiveDeepSEAScoreList(scoreList) {
  return {
    type: RECEIVE_DEEPSEA_SCORE_LIST,
    scoreList,
  };
}

export function resetDeepSEAState() {
  return {
    type: RESET_DEEPSEA_STATE,
  };
}

export function setDeepSEAJobs(jobs) {
  return {
    type: SET_DEEPSEA_JOBS,
    jobs,
  };
}

export function fetchDeepSEAFeatures(modelName) {
  return async (dispatch, getState, { fetch }) => {
    const response = await fetch(`${BASE_URI}deepsea/${modelName}/features/`);
    const json = await response.json();
    dispatch(receiveDeepSEAFeatures(json));
  };
}

export function fetchDeepSEAModels() {
  return async (dispatch, getState, { fetch }) => {
    const response = await fetch(`${BASE_URI}deepsea/`);
    const json = await response.json();
    dispatch(receiveDeepSEAModels(json));
  };
}

export function fetchDeepSEAFeaturePredictions(modelName, params) {
  return async (dispatch, getstate, { fetch }) => {
    dispatch(requestDeepSEAFeaturePredictions());
    const queryStr = new URLSearchParams(params).toString();
    const response = await fetch(
      `${BASE_URI}deepsea/${modelName}/feature_predictions/?${queryStr}`,
    );
    const featurePredictions = await response.json();
    dispatch(receiveDeepSEAFeaturePredictions(featurePredictions));
  };
}

export function fetchDeepSEAFeaturesAndPredictions(modelName, params) {
  return async (dispatch, getstate, { fetch }) => {
    if (!modelName) return;
    dispatch(requestDeepSEAFeaturePredictions());

    const { expecto, ...predictionParams } = params;
    const predictionQueryStr = Object.keys(predictionParams)
      .map(param => `${param}=${params[param]}`)
      .join('&');

    const featureParams = new URLSearchParams();
    if (params.expecto) featureParams.append('expecto', true);
    if (params.score_type)
      featureParams.append('score_type', params.score_type);
    const featureQueryString = featureParams.toString();

    let response = await fetch(
      `${BASE_URI}deepsea/${modelName}/features/?${featureQueryString}`,
    );

    const features = await response.json();

    response = await fetch(
      `${BASE_URI}deepsea/${modelName}/feature_predictions/?${predictionQueryStr}`,
    );
    let featurePredictions = [];
    let featureQuerySuccess = false;
    if (response.status === 200) {
      featureQuerySuccess = true;
      featurePredictions = await response.json();
    }
    dispatch(
      receiveDeepSEAFeaturesAndPredictions(
        features,
        featurePredictions,
        featureQuerySuccess,
      ),
    );
  };
}

export function fetchDeepSEAScoreList(job) {
  return async (dispatch, getstate, { fetch }) => {
    const response = await fetch(`${BASE_URI}deepsea/jobs/${job}/score_types`);
    const scores = await response.json();
    dispatch(receiveDeepSEAScoreList(scores));
  };
}

export function fetchDeepSEAScores(scoreTypes, job) {
  return async (dispatch, getState, { fetch }) => {
    const variantScores = {};
    const promises = [];
    scoreTypes.forEach(scoreType => {
      let scoreLevel = 'variant_scores';
      if (scoreType.level === 'SEQUENCE') scoreLevel = 'sequence_scores';

      const promise = fetch(
        `${BASE_URI}deepsea/jobs/${job}/${scoreLevel}/?score_type=${scoreType.short_name}`,
      ).then(response => response.json());
      promises.push(promise);
    });
    const scores = await Promise.all(promises);
    scores.forEach(score => {
      variantScores[score.score_type] = score;
    });

    dispatch(receiveDeepSEAVariantScores(variantScores));
  };
}

export function fetchDeepSEAJobFeaturePredictions(jobName, params) {
  return async (dispatch, getstate, { fetch }) => {
    dispatch(requestDeepSEAFeaturePredictions());
    const queryStr = new URLSearchParams(params).toString();
    const response = await fetch(
      `${BASE_URI}deepsea/jobs/${jobName}/feature_predictions/?${queryStr}`,
    );
    const featurePredictions = await response.json();
    dispatch(receiveDeepSEAJobFeaturePredictions(featurePredictions));
  };
}

export function fetchDeepSEAJobFeaturesAndPredictions(
  jobName,
  modelName,
  params,
) {
  return async (dispatch, getState, { fetch }) => {
    dispatch(requestDeepSEAFeaturePredictions());

    const { expecto, ...predictionParams } = params;
    const predictionQueryStr = new URLSearchParams(predictionParams).toString();

    const featureParams = new URLSearchParams();
    if (params.expecto) featureParams.append('expecto', true);
    if (params.score_type)
      featureParams.append('score_type', params.score_type);
    const featureQueryString = featureParams.toString();

    let response = await fetch(
      `${BASE_URI}deepsea/${modelName}/features/?${featureQueryString}`,
    );
    const features = await response.json();
    response = await fetch(
      `${BASE_URI}deepsea/jobs/${jobName}/feature_predictions/?${predictionQueryStr}`,
    );
    const featurePredictions = await response.json();
    dispatch(
      receiveDeepSEAFeaturesAndPredictions(features, featurePredictions),
    );
  };
}

export function fetchDeepSEAJobSubmittedVariants() {
  return async (dispatch, getState, { fetch }) => {
    const { deepsea } = getState();
    const response = await fetch(
      deepsea.selectedJob.files.uploaded_query.url,
    ).then(x => x.text());
    return response;
  };
}
