/* eslint-disable camelcase */ // API returns many snakecase values
import {
  GeneMatch,
  organismSlugs,
  OrganismSlugString,
} from '../../shared/sharedTypes';
import { BASE_URI, SEARCH_MULTI_URI, SEEK_URI } from '../../../settings';
import {
  SeekResults,
  SeekMachineContext,
  SeekMachineEvent,
} from '../seekTypes';
import { getSeekBodyTag } from './seekUtils';

const seekServices = {
  fetchDatasets: async () =>
    Promise.all(
      organismSlugs.map((orgSlug: any) => {
        const uri = `${BASE_URI}compendiums/modseek-${orgSlug}/datasets/`;
        return fetch(uri).then(async r => {
          // Throw an error if the response is not OK. At which point the
          // machine will transition to the error state and cause a delayed retry.
          if (!r.ok) throw new Error(await r.text());
          return { [orgSlug]: await r.json() };
        });
      }),
    ).then(results => {
      return results.reduce((acc, result) => {
        return { ...acc, ...result };
      }, {});
    }),
  fetchEntrezDetail: (context: SeekMachineContext, event: SeekMachineEvent) => {
    // @ts-expect-error - REVISIT in V5 - https://xstate.js.org/docs/guides/typescript.html#troubleshooting
    const queryGeneEntrez = event.data.query_genes.map(g => g[0].toString());
    const expressionResultEntrez = Object.keys(
      // @ts-expect-error - REVISIT in V5 - https://xstate.js.org/docs/guides/typescript.html#troubleshooting
      event.data.gene_expressions[
        // @ts-expect-error - REVISIT in V5 - https://xstate.js.org/docs/guides/typescript.html#troubleshooting
        Object.keys(event.data.gene_expressions)[0]
      ],
    );
    const entrezIds = Array.from(
      new Set([...queryGeneEntrez, ...expressionResultEntrez]),
    );
    const q = entrezIds.join('+');
    const url = `${SEARCH_MULTI_URI}/`;
    return fetch(url, {
      method: 'POST',
      body: `{"q": "${q}"}`,
      headers: { 'Content-Type': 'application/json' },
    }).then(res => res.json());
  },
  fetchSeek: (context: SeekMachineContext, event: SeekMachineEvent) => {
    const { queryOrganismSlug, selectedTerms, queryGeneMatches } = context;
    // @ts-expect-error - REVISIT in V5 - https://xstate.js.org/docs/guides/typescript.html#troubleshooting
    const { bodyTag } = event;

    const handleResponse = (json: { seek_results: SeekResults }) => {
      const { seek_results } = json;
      // Post process the Seek Result to add convenient lookup
      //  of entrez to symbol
      const newJson = { ...json };
      Object.keys(seek_results).forEach((key: string) => {
        const entrezLookup: Record<string, string> = {};
        const organismKey = key as OrganismSlugString;
        seek_results[organismKey].gene_scores.forEach(score => {
          entrezLookup[score[0]] = score[2];
        });
        newJson.seek_results[organismKey].entrez_lookup = entrezLookup;
      });
      return newJson;
    };

    if (bodyTag !== undefined) {
      // GET request
      const url = `${SEEK_URI}?body_tag=${encodeURIComponent(bodyTag)}`;
      return fetch(url, {
        method: 'GET',
      })
        .then(res => res.json())
        .then(handleResponse);
    }

    // Else -> POST request
    const body = {
      body_tag: getSeekBodyTag(context),
      query_genes: queryGeneMatches.reduce(
        // Remove duplicates GeneMatches before querying
        (accumulator: GeneMatch[], current) => {
          const index = accumulator.findIndex(
            (obj: GeneMatch) => obj.entrez === current.entrez,
          );
          if (index === -1) {
            accumulator.push(current);
          }
          return accumulator;
        },
        [],
      ),
      term_list: selectedTerms,
      query_organism: queryOrganismSlug,
    };

    return fetch(SEEK_URI, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => res.json())
      .then(handleResponse);
  },
  fetchExpression: (context: SeekMachineContext) => {
    const {
      page,
      pageY,
      queryOrganismSlug,
      queryGeneMatches,
      expressionFilterMatches,
      referenceOrganismSlug,
      selectedTerms,
      selectedDatasets,
    } = context;

    const datasets = selectedDatasets
      .map(dataset => dataset?.value)
      .filter(value => value !== undefined);
    const body = {
      body_tag: getSeekBodyTag(context),
      datasets,
      page: page[referenceOrganismSlug],
      page_y: pageY[referenceOrganismSlug],
      reference_organism: referenceOrganismSlug,
      filter_entrez: expressionFilterMatches.map(geneMatch => geneMatch.entrez),
      query_genes: queryGeneMatches,
      term_list: selectedTerms,
      query_organism: queryOrganismSlug,
    };

    return fetch(`${SEEK_URI}expression/`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
    }).then(res => res.json());
  },
  fetchEnrichment: (context: SeekMachineContext) => {
    const { queryGeneMatches, queryOrganismSlug, selectedTerms } = context;
    const body = {
      body_tag: getSeekBodyTag(context),
      query_genes: queryGeneMatches,
      term_list: selectedTerms,
      query_organism: queryOrganismSlug,
    };

    return fetch(`${SEEK_URI}enrichment/`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
    }).then(res => res.json());
  },
};

export default seekServices;
