import sha1 from 'js-sha1';
import { ANNOTATION_DATABASES, BASE_URI } from '../settings';
import { updateHistory } from '../history';

/*
 * Action types
 */
export const SELECT_NODE = 'SELECT_NODE';
export const SELECT_EDGE = 'SELECT_EDGE';
export const SET_EDGE_CUTOFF = 'SET_EDGE_CUTOFF';
export const SET_NODE_CUTOFF = 'SET_NODE_CUTOFF';

export const SELECT_INTEGRATION = 'SELECT_INTEGRATION';
export const RECEIVE_INTEGRATIONS = 'RECEIVE_INTEGRATIONS';

export const ADD_NETWORK = 'ADD_NETWORK';
export const REMOVE_NETWORK = 'REMOVE_NETWORK';

export const REQUEST_NETWORK = 'REQUEST_NETWORK';
export const RECEIVE_NETWORK = 'RECEIVE_NETWORK';

export const RECEIVE_EVIDENCE = 'RECEIVE_EVIDENCE';

export const REQUEST_TERMS = 'REQUEST_TERMS';
export const RECEIVE_TERMS = 'RECEIVE_TERMS';

export const RECEIVE_DATATYPES = 'RECEIVE_DATATYPES';
export const SELECT_DATATYPES = 'SELECT_DATATYPES';
export const ADD_DATATYPE = 'ADD_DATATYPE';
export const REMOVE_DATATYPE = 'REMOVE_DATATYPE';

export const RECEIVE_MULTINETWORK_EDGE = 'RECEIVE_MULTINETWORK_EDGE';

export const ADD_GENESET = 'ADD_GENESET';
export const REMOVE_GENESET = 'REMOVE_GENESET';

export const ADD_NETWORK_GROUP = 'ADD_NETWORK_GROUP';

export const RECEIVE_RELEVANT_NETWORKS = 'RECEIVE_RELEVANT_NETWORKS';

export const SET_CURRENT_SEARCH_GENES = 'SET_CURRENT_SEARCH_GENES';
export const SET_CURRENT_SEARCH_TEXT = 'SET_CURRENT_SEARCH_TEXT';
export const SET_CURRENT_SEARCH_INTEGRATION = 'SET_CURRENT_SEARCH_INTEGRATION';
export const CLEAR_CURRENT_SEARCH = 'CLEAR_CURRENT_SEARCH';
export const CLEAR_RELEVANT_NETWORKS = 'CLEAR_RELEVANT_NETWORKS';
export const SET_SEARCH_TARGET = 'SET_SEARCH_TARGET';

/*
 * Action creator to track information about the current search query
 */
export function setCurrentSearchGenes(list) {
  return { type: SET_CURRENT_SEARCH_GENES, currentSearchGenes: list };
}

export function setCurrentSearchText(text) {
  return { type: SET_CURRENT_SEARCH_TEXT, currentSearchText: text };
}

export function setCurrentSearchIntegration(integration) {
  return {
    type: SET_CURRENT_SEARCH_INTEGRATION,
    currentSearchIntegration: integration,
  };
}

export function clearCurrentSearch() {
  return { type: CLEAR_CURRENT_SEARCH };
}

export function clearRelevantNetworks() {
  return { type: CLEAR_RELEVANT_NETWORKS };
}

export function setSearchTarget(target) {
  return { type: SET_SEARCH_TARGET, searchTarget: target };
}

/*
 * Action creators for handling integrations for users to select and generate networks of
 */

export function receiveIntegrations(json) {
  updateHistory({ integrations: json });
  return { type: RECEIVE_INTEGRATIONS, integrations: json };
}

export function selectIntegration(integration, id) {
  updateHistory({ selectedIntegration: integration });
  return { type: SELECT_INTEGRATION, integration, id };
}

/*
 * Action creators for requesting and receiving terms for calculating enrichment
 */

export function requestTerms(id) {
  return { type: REQUEST_TERMS, id };
}

export function receiveTerms(json, id) {
  return { type: RECEIVE_TERMS, terms: json, id };
}

/*
 * Action creators for interacting with a network
 */

export function selectNode(selectedNode, id) {
  return { type: SELECT_NODE, selectedNode, id };
}

export function selectEdge(selectedEdge, id) {
  return { type: SELECT_EDGE, selectedEdge, id };
}

export function setNodeCutoff(nodeCutoff, id) {
  return { type: SET_NODE_CUTOFF, nodeCutoff, id };
}

export function setEdgeCutoff(edgeCutoff, id) {
  return { type: SET_EDGE_CUTOFF, edgeCutoff, id };
}

export function receiveEvidence(edge, json, id) {
  return { type: RECEIVE_EVIDENCE, edge, evidence: json, id };
}

/*
 *  Action creators for adding, removing user networks
 */

export function receiveNetwork(json, id) {
  return { type: RECEIVE_NETWORK, network: json, id };
}

export function requestNetwork(integration, id) {
  return { type: REQUEST_NETWORK, integration, id };
}

export function receiveRelevantNetworks(networks) {
  updateHistory({ relevantNetworks: networks });
  return { type: RECEIVE_RELEVANT_NETWORKS, networks };
}

export function addNetwork(integration, genes, id) {
  return { type: ADD_NETWORK, integration, genes, id };
}

export function removeNetwork(id) {
  return { type: REMOVE_NETWORK, id };
}

/*
 *  Action creators for adding, removing dataset datatypes
 */
export function selectDatatypes(datatypes, id) {
  return { type: SELECT_DATATYPES, datatypes, id };
}

export function addDatatype(datatype, id) {
  return { type: ADD_DATATYPE, datatype, id };
}

export function removeDatatype(id, index) {
  return { type: REMOVE_DATATYPE, id, index };
}

export function receiveDatatypes(json) {
  return { type: RECEIVE_DATATYPES, datatypes: json };
}

export function receiveMultiNetworkEdge(edge, json, id) {
  return { type: RECEIVE_MULTINETWORK_EDGE, id, edge, edges: json };
}

/*
 * Action creators for adding and removing selected genesets, which will highlight
 * nodes in the networks
 */

export function addGeneset(geneset, id) {
  return { type: ADD_GENESET, geneset, id };
}

export function removeGeneset(id) {
  return { type: REMOVE_GENESET, id };
}

export function addNetworkGroup(group) {
  return { type: ADD_NETWORK_GROUP, id: { group } };
}

/*
 * Convenience actions that additionally update d3
 */

export function removeNetworkUpdateD3(id) {
  return (dispatch, getState) => {
    dispatch(removeNetwork(id));
    const { networkGroups } = getState();
    const { group } = id;
    const networks = networkGroups[group];

    const d3nets = networks.map(net => net.d3);
    d3nets.forEach(net => {
      net.bindNetworks(d3nets);
      net.updateStyling();
    });
  };
}

/*
 *  Action creators for API calls
 */
export function fetchIntegrations() {
  return async (dispatch, getState, { fetch }) => {
    const { integrations } = getState();
    if (integrations && integrations.length) return;

    const response = await fetch(`${BASE_URI}integrations/`);
    const json = await response.json();
    dispatch(receiveIntegrations(json));
  };
}

export function fetchEvidence(edge, id, integration, datatypes) {
  return async (dispatch, getState, { fetch }) => {
    const { networkGroups } = getState();
    const { group, idx } = id;
    const network = networkGroups[group][idx];

    // Query for evidence using selected (or given) datatypes and integration
    if (!datatypes && network) datatypes = network.selectedDatatypes; // eslint-disable-line no-param-reassign
    if (!integration && network) integration = network.selectedIntegration; // eslint-disable-line no-param-reassign

    let url = `${BASE_URI}integrations/${integration.slug}/evidence/?source=${edge.source.entrez}&target=${edge.target.entrez}`;
    if (datatypes) {
      datatypes.forEach(type => {
        url += `&datatypes=${type.slug}`;
      });
    }
    url += '&limit=50';

    const response = await fetch(url);
    const json = await response.json();
    dispatch(receiveEvidence(edge, json, id));
  };
}

export function fetchAnnotatedTerms(genes, id) {
  return async (dispatch, getState, { fetch }) => {
    dispatch(requestTerms(id));
    let termsList = [];
    const promises = [];

    // Query each database for terms annotated with genes
    for (let k = 0, N = ANNOTATION_DATABASES.length; k < N; k += 1) {
      const db = ANNOTATION_DATABASES[k];
      const url = `${BASE_URI}terms/annotated/?entrez=${genes
        .map(gene => gene.entrez)
        .join('&entrez=')}&database=${db}`;
      const promise = fetch(url).then(response => response.json());
      promises.push(promise);
    }

    // Wait for all of the promises to finish
    await Promise.all(promises).then(values => {
      values.forEach(terms => {
        termsList = termsList.concat(terms);
      });
    });

    dispatch(receiveTerms(termsList, id));
  };
}

export function fetchDatatypes() {
  return async (dispatch, getState, { fetch }) => {
    const { datatypes } = getState();
    if (datatypes && datatypes.length) return;

    const response = await fetch(`${BASE_URI}datatypes/`);
    const json = await response.json();
    dispatch(receiveDatatypes(json));
  };
}

export function fetchMultiNetworkEdge(edge, id) {
  return async (dispatch, getState, { fetch }) => {
    const { networkGroups } = getState();
    const { group, idx } = id;
    const network = networkGroups[group][idx];

    let url =
      `${BASE_URI}integrations/edges/` +
      `?source=${edge.source.entrez}&target=${edge.target.entrez}`;

    if (network) {
      network.selectedDatatypes.forEach(type => {
        url += `&datatypes=${type.slug}`;
      });
      if (network.selectedIntegration.context.term)
        url += `&database=${network.selectedIntegration.context.term.database.slug}`;
    }

    const response = await fetch(url);
    const json = await response.json();
    dispatch(receiveMultiNetworkEdge(edge, json, id));
  };
}

export function fetchNetworkWithTerms(
  integration,
  genes,
  id,
  datatypes = null,
) {
  return async (dispatch, getState, { fetch }) => {
    dispatch(requestNetwork(integration, id));
    let url = `${BASE_URI}integrations/${
      integration.slug
    }/network/?entrez=${genes.map(gene => gene.entrez).join('&entrez=')}`;
    if (datatypes) {
      datatypes.forEach(type => {
        url += `&datatypes=${type.slug}`;
      });
    }
    const response = await fetch(url);

    // If error, store message and bail
    if (!response.ok) {
      let error = response.statusText;
      if (response.status === 404)
        error =
          'Unfortunately, the query gene(s) are not in our ' +
          'data compendium and no predictions are available.';
      dispatch(receiveNetwork({ error }, id));
      return;
    }

    const json = await response.json();

    if (json.mincut) dispatch(setEdgeCutoff(json.mincut, id));

    dispatch(receiveNetwork(json, id));

    // Initialize network with all data types
    if (!datatypes) {
      dispatch(fetchDatatypes());
      const state = getState();
      dispatch(selectDatatypes(state.datatypes, id));
    }

    dispatch(fetchAnnotatedTerms(json.genes, id));
  };
}

export function fetchRelevantNetworks(genes, database) {
  const body = `{ "entrez": [${genes.map(gene => `"${gene.entrez}"`)}] }`;
  const bodyTag = sha1(body);
  let url = `${BASE_URI}integrations/relevant/?body_tag=${bodyTag}`;
  if (database) {
    url += `?database=${database}`;
  }
  return fetch(url, {
    method: 'POST',
    body,
    headers: { 'Content-Type': 'application/json' },
  });
}

export function fetchRelevantNetworksAction(genes, database) {
  return async dispatch => {
    const response = await fetchRelevantNetworks(genes, database);
    if (response && response.ok) {
      const json = await response.json();
      dispatch(receiveRelevantNetworks(json));
    } else {
      dispatch(receiveRelevantNetworks([]));
    }
  };
}
