/*
 * Reducers for adding and removing user-selected genes
 *
 */
import { RECEIVE_DATASETS, RECEIVE_DATABASES } from '../actions/DataActions';

function data(
  state = {
    datasets: [],
    databases: [],
  },
  action,
) {
  switch (action.type) {
    case RECEIVE_DATASETS:
      return Object.assign({}, state, {
        datasets: action.datasets,
      });
    case RECEIVE_DATABASES:
      return Object.assign({}, state, {
        databases: action.databases,
      });
    default:
      return state;
  }
}

export default { data };
