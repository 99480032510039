import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

import { BASE_URI } from '../settings';
import { cancelFetchOnReentry } from '../core/util';

/*
 * Variants action types
 */
export const RECEIVE_VARIANT_TISSUE_PREDICTIONS =
  'RECEIVE_VARIANT_TISSUE_PREDICTIONS';

export const RECIEVE_VARIANTS = 'RECIEVE_VARIANTS';

export const RECEIVE_VARIANT_FEATURE_NAMES = 'RECEIVE_VARIANT_FEATURE_NAMES';

export const SET_VARIANT_ERRORS = 'SET_VARIANT_ERRORS';

/*
 * Variants action creators
 */

export function recieveVariants(variants) {
  return {
    type: RECIEVE_VARIANTS,
    variants,
  };
}

export function receiveTissueVariantPredictions(gene, predictions) {
  return {
    type: RECEIVE_VARIANT_TISSUE_PREDICTIONS,
    gene,
    predictions,
  };
}

export function receiveVariantFeatureNames(featureNames) {
  return {
    type: RECEIVE_VARIANT_FEATURE_NAMES,
    featureNames,
  };
}
export function setVariantErrors(errors) {
  return {
    type: SET_VARIANT_ERRORS,
    errors,
  };
}

export function fetchVariants(search) {
  return async (dispatch, getState, { fetch }) => {
    let response;
    try {
      response = await cancelFetchOnReentry(fetch, search);
    } catch (errors) {
      dispatch(setVariantErrors({ errors }));
      throw errors;
    }

    if (response && response.status === 200) {
      const json = await response.json();
      dispatch(recieveVariants(json));
    } else if (response) {
      dispatch(setVariantErrors({ errors: response.statusText }));
    }
  };
}

export function fetchVariantFeatureNames(featureLabel) {
  return async (dispatch, getstate, { fetch }) => {
    const response = await fetch(
      `${BASE_URI}variants/features/?label=${featureLabel}`,
    );
    const featureNames = await response.json();
    dispatch(receiveVariantFeatureNames(featureNames.map(f => f.name)));
  };
}

export function fetchTissueVariantPredictions(gene, database) {
  const featureParams = new URLSearchParams();
  if (database) featureParams.append('database', database);
  featureParams.append('collapse', 'true');
  const featureQueryString = featureParams.toString();

  return async (dispatch, getState, { fetch }) => {
    const response = await fetch(
      `${BASE_URI}genes/${gene.entrez}/tissue_variants/?${featureQueryString}`,
    );
    const predictions = await response.json();
    if (response.status === 200) {
      predictions.tissues.sort((t1, t2) => {
        if (t1.title < t2.title) return -1;
        if (t1.title > t2.title) return 1;
        return 0;
      });
    }
    dispatch(receiveTissueVariantPredictions(gene.entrez, predictions));
  };
}
