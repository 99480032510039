/*
 * Reducers for genome browser related interface elements
 *
 */

import {
  UPDATE_GENOMIC_RANGE,
  SET_SELECTED_VARIANT,
  SET_SELECTED_VARIANT_AND_UPDATE_RANGE,
  SET_GENOME_BROWSER_WARNING,
} from '../actions/GenomeActions';

function genome(
  state = {
    chromosome: 'chr1',
    startPosition: 2230000,
    endPosition: 2440000,
    selectedVariant: {},
    warning: null,
  },
  action,
) {
  switch (action.type) {
    case UPDATE_GENOMIC_RANGE:
      return Object.assign({}, state, {
        chromosome: action.chromosome,
        startPosition: action.startPosition,
        endPosition: action.endPosition,
      });
    case SET_SELECTED_VARIANT:
      return Object.assign({}, state, {
        selectedVariant: action.variant,
      });
    case SET_SELECTED_VARIANT_AND_UPDATE_RANGE:
      return Object.assign({}, state, {
        chromosome: action.chromosome,
        startPosition: action.startPosition,
        endPosition: action.endPosition,
        selectedVariant: action.variant,
      });
    case SET_GENOME_BROWSER_WARNING:
      return Object.assign({}, state, {
        warning: action.warning,
      });
    default:
      return state;
  }
}

const genomeReducers = {
  genome,
};

export default genomeReducers;
