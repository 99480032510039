import { GeneMatch } from '../../shared/sharedTypes';
import {
  SeekMachineContext,
  SeekMachineEvent,
  SeekMachineState,
} from '../seekTypes';

const seekGuards = {
  hasErrors: (context: SeekMachineContext): boolean => {
    const { errors } = context;
    return Object.keys(errors).length > 0;
  },
  hasHumanReferenceOrganism: (context: SeekMachineContext): boolean => {
    const { referenceOrganismSlug } = context;
    return referenceOrganismSlug === 'homo-sapiens';
  },
  hasDifferentFilterContents: (
    context: SeekMachineContext,
    event: SeekMachineEvent,
  ): boolean => {
    const { expressionFilterMatches } = context;
    if (event.type === 'NEW_EXPRESSION_GENE_FILTER_MATCHES') {
      const { expressionFilterMatches: newFilterGeneMatches } = event;
      return (
        JSON.stringify(
          expressionFilterMatches.map(geneMatch => geneMatch.entrez),
        ) !==
        JSON.stringify(
          newFilterGeneMatches.map((geneMatch: GeneMatch) => geneMatch.entrez),
        )
      );
    }
    return false;
  },
  needsNewExpressionResult: (
    context: SeekMachineContext,
    event: SeekMachineEvent,
    { state }: { state: SeekMachineState },
  ): boolean => {
    const { expressionResults, queryEntrez, page, pageY } = context;

    if (state.matches('seekResultManager.hasSeekResult.no')) return false;
    if (event.type === 'SELECT_REFERENCE_ORGANISM') {
      const { expressionFilterMatches } = context;
      const { referenceOrganismSlug } = event;
      const currentPage = page[referenceOrganismSlug];

      return (
        expressionFilterMatches.length > 0 || // If we have a filter, needs new result
        expressionResults[referenceOrganismSlug][currentPage] === undefined // Is there an existing result?
      );
    }
    if (event.type === 'FETCH_EXPRESSION') {
      const { pageNumber, pageYNumber: eventPageY } = event;
      const { referenceOrganismSlug, selectedDatasets } = context;
      const currentPage = pageNumber || page[referenceOrganismSlug];
      const expressionResult =
        expressionResults[referenceOrganismSlug][currentPage];

      return (
        eventPageY !== pageY[referenceOrganismSlug] || // Do we need more results on the Y Axis?
        expressionResult === undefined || // No result at all
        // Compare two arrays of entrez to see if contents are equal
        JSON.stringify(queryEntrez.sort()) !==
          JSON.stringify(
            expressionResult.query_genes.map(qGene => qGene[0]).sort(),
          ) ||
        // Compare two arrays of dataset names to see if contents are equal
        (expressionResult?.dataset_metadata &&
          JSON.stringify(
            Object.keys(expressionResult.dataset_metadata).sort(),
          )) !==
          JSON.stringify(selectedDatasets.map(dataset => dataset.value).sort())
      );
    }
    return false;
  },
};

export default seekGuards;
