/*
 * Reducers for retrieving gene/term predictions
 *
 */

import {
  RECEIVE_TERM_PREDICTIONS,
  RECEIVE_TERM_DATABASES,
} from '../actions/PredictionActions';

function predictions(state = {}, action) {
  switch (action.type) {
    case RECEIVE_TERM_PREDICTIONS:
      return Object.assign({}, state, {
        [action.database]: action.terms,
      });
    default:
      return state;
  }
}

function termPredictions(state = {}, action) {
  switch (action.type) {
    case RECEIVE_TERM_PREDICTIONS:
      return Object.assign({}, state, {
        [action.gene]: predictions(state[action.gene], action),
      });
    default:
      return state;
  }
}

function termDatabases(state = [], action) {
  switch (action.type) {
    case RECEIVE_TERM_DATABASES:
      return action.databases;
    default:
      return state;
  }
}

const PredictionReducers = {
  termPredictions,
  termDatabases,
};

export default PredictionReducers;
