/*
 * action types
 */
export const NEXT_TUTORIAL = 'NEXT_TUTORIAL';
export const PREVIOUS_TUTORIAL = 'PREVIOUS_TUTORIAL';
export const START_TUTORIAL = 'START_TUTORIAL';
export const STOP_TUTORIAL = 'STOP_TUTORIAL';

/*
 * action creators
 */
export function nextTutorial() {
  return {
    type: NEXT_TUTORIAL,
  };
}

export function previousTutorial() {
  return {
    type: PREVIOUS_TUTORIAL,
  };
}

export function startTutorial() {
  return {
    type: START_TUTORIAL,
  };
}

export function stopTutorial() {
  return {
    type: STOP_TUTORIAL,
  };
}
