import { combineReducers } from 'redux';

import CommunityReducers from './CommunityReducers';
import DataReducers from './DataReducers';
import GeneReducers from './GeneReducers';
import GenesetReducers from './GenesetReducers';
import NetworkReducers from './NetworkReducers';
import PredictionReducers from './PredictionReducers';
import TutorialReducers from './TutorialReducers';
import VariantReducers from './VariantReducers';
import GenomeReducers from './GenomeReducers';
import DeepSEAReducers from './DeepSEAReducers';

const appReducer = combineReducers(
  Object.assign(
    CommunityReducers,
    DataReducers,
    GeneReducers,
    GenesetReducers,
    NetworkReducers,
    PredictionReducers,
    TutorialReducers,
    VariantReducers,
    GenomeReducers,
    DeepSEAReducers,
  ),
);

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
