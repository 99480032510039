import { pushHistory, updateHistory } from '../history';

/*
 * Action types
 */
export const CLEAR_COMMUNITY_DATA = 'CLEAR_COMMUNITY_DATA';
export const SET_COMMUNITY_COLUMN_WIDTH = 'SET_COMMUNITY_COLUMN_WIDTH';
export const SET_COMMUNITY_STATE = 'SET_COMMUNITY_STATE';
export const SET_COMMUNITY_WARNING = 'SET_COMMUNITY_WARNING';

function shouldHandleError(err) {
  // At the moment, we're only handling this one specific error
  return err.name === 'FireFoxMaxHistoryError';
}

/*
 * Action creators for requesting and receiving terms for calculating enrichment
 */
export function setCommunityWarning(warning) {
  return { type: SET_COMMUNITY_WARNING, warning };
}

export function setCommunityColumnWidth(columnWidth) {
  return { type: SET_COMMUNITY_COLUMN_WIDTH, columnWidth };
}

export function setCommunityState(states) {
  return { type: SET_COMMUNITY_STATE, ...states };
}

export function setCommunityStatePlusHistory(
  states,
  url = null,
  fromHistory = false,
  forceUpdate = false,
) {
  return async (dispatch, getState) => {
    const { community } = getState();
    if (url && !forceUpdate) {
      pushHistory(url, {
        community: Object.assign({}, community, states),
      }).catch(err => {
        if (shouldHandleError(err)) dispatch(setCommunityWarning(err.message));
        else throw err;
      });
    } else if (!fromHistory || forceUpdate) {
      updateHistory(
        {
          community: Object.assign({}, community, states),
        },
        url,
      ).catch(err => {
        if (shouldHandleError(err)) dispatch(setCommunityWarning(err.message));
        else throw err;
      });
    }
    dispatch(setCommunityState(states));
  };
}

export function clearCommunityData(butPreserve = {}) {
  return { type: CLEAR_COMMUNITY_DATA, ...butPreserve };
}
