import { BASE_URI, TERM_DATABASES } from '../settings';

/*
 * action types
 */

export const REQUEST_TERM_PREDICTIONS = 'REQUEST_TERM_PREDICTIONS';
export const RECEIVE_TERM_PREDICTIONS = 'RECEIVE_TERM_PREDICTIONS';

export const RECEIVE_TERM_DATABASES = 'RECEIVE_TERM_DATABASES';

/*
 * action creators
 */

export function receiveTermPredictions(database, gene, terms) {
  return {
    type: RECEIVE_TERM_PREDICTIONS,
    database,
    gene,
    terms,
  };
}

export function requestTermPredictions(database) {
  return {
    type: REQUEST_TERM_PREDICTIONS,
    database,
  };
}

export function receiveTermDatabases(databases) {
  return {
    type: RECEIVE_TERM_DATABASES,
    databases,
  };
}

export function fetchTermDatabases() {
  const TERM_DATABASE_SLUGS = TERM_DATABASES.map(db => db.slug);

  return async (dispatch, getState, { fetch }) => {
    const url = `${BASE_URI}databases/`;
    const response = await fetch(url);
    let databases = await response.json();
    databases = databases.filter(
      db => TERM_DATABASE_SLUGS.indexOf(db.slug) !== -1,
    );
    dispatch(receiveTermDatabases(databases));
  };
}

export function fetchTermPredictions(gene, database, params = {}) {
  return async (dispatch, getState, { fetch }) => {
    dispatch(requestTermPredictions(database));

    let url = `${BASE_URI}genes/${gene.entrez}/predictions/?`;
    if (database) url += `&database=${database}`;

    Object.entries(params).forEach(([key, value]) => {
      url += `&${key}=${value}`;
    });

    let response = await fetch(url);
    const predictions = await response.json();

    url = `${BASE_URI}genes/${gene.entrez}/annotations/?`;
    if (database) url += `&database=${database}`;

    Object.entries(params).forEach(([key, value]) => {
      url += `&${key}=${value}`;
    });

    response = await fetch(url);
    const annotations = await response.json();

    const annots = {};
    const terms = [];
    // Iterate over annotations, aggregating associated pubs
    for (let i = 0, n = annotations.length; i < n; i += 1) {
      const annot = annotations[i];
      if (!annots[annot.term.identifier]) {
        annots[annot.term.identifier] = {
          database: annot.database,
          evidence: annot.evidence,
          pubs: annot.pub ? [annot.pub] : [],
          term: annot.term,
          annotated: true,
          score: 1.0,
        };
        terms.push(annots[annot.term.identifier]);
      } else {
        annots[annot.term.identifier].pubs.push(annot.pub);
      }
    }

    for (let i = 0, n = predictions.length; i < n; i += 1) {
      const pred = predictions[i];
      pred.predScore = pred.score;
      if (!annots[pred.term.identifier]) terms.push(pred);
      else {
        annots[pred.term.identifier].predScore = pred.score;
      }
    }

    dispatch(receiveTermPredictions(database, gene.entrez, terms));
  };
}
