import { ADD_GENESET, REMOVE_GENESET } from '../actions/NetworkActions';

let geneset_idx = 0;

function geneSets(state = {}, action) {
  switch(action.type) {
    case ADD_GENESET:
      return Object.assign({}, state, {
        [action.id] : Object.assign({}, action.geneset, {'id':geneset_idx++})
      })
    case REMOVE_GENESET:
      let obj = Object.assign({}, state);
      delete obj[action.id]
      return obj;
    default:
      return state;
  }
}

var GenesetReducers = {
  geneSets
};

export default GenesetReducers;
