/*
 * Reducers for retrieving gene/ predictions
 *
 */

import {
  RECEIVE_VARIANT_TISSUE_PREDICTIONS,
  RECIEVE_VARIANTS,
  RECEIVE_VARIANT_FEATURE_NAMES,
  SET_VARIANT_ERRORS,
} from '../actions/VariantActions';

function geneVariantTissuePredictions(state = {}, action) {
  switch (action.type) {
    case RECEIVE_VARIANT_TISSUE_PREDICTIONS:
      return Object.assign({}, state, {
        [action.gene]: action.predictions,
      });
    default:
      return state;
  }
}

function variants(state = {}, action) {
  switch (action.type) {
    case RECIEVE_VARIANTS:
      return action.variants;
    case SET_VARIANT_ERRORS:
      return action.errors;
    default:
      return state;
  }
}

function variantFeatureNames(state = [], action) {
  switch (action.type) {
    case RECEIVE_VARIANT_FEATURE_NAMES:
      return action.featureNames;
    default:
      return state;
  }
}

const VariantReducers = {
  geneVariantTissuePredictions,
  variants,
  variantFeatureNames,
};

export default VariantReducers;
