/*
 * Reducers for retrieving deepsea predictions
 */
import { setCookie } from '../core/util';

import {
  SET_DEEPSEA_JOBS,
  RECEIVE_DEEPSEA_FEATURES,
  RECEIVE_DEEPSEA_MODELS,
  RECEIVE_DEEPSEA_FEATURE_PREDICTIONS,
  RECEIVE_DEEPSEA_FEATURES_AND_PREDICTIONS,
  RECEIVE_DEEPSEA_JOB,
  RECEIVE_DEEPSEA_JOB_FEATURE_PREDICTIONS,
  RECEIVE_DEEPSEA_VARIANT_SCORES,
  RECEIVE_DEEPSEA_SCORE_LIST,
  REQUEST_INSILICO_FEATURE_DATA,
  REQUEST_DEEPSEA_FEATURE_PREDICTIONS,
  SET_SELECTED_DEEPSEA_JOB,
  SET_DEEPSEA_JOB_VALIDATION_ERRORS,
  SET_INSILICO_FEATURE_DATA,
  SET_SELECTED_INSILICO_DATA,
  SET_SELECTED_DEEPSEA_VARIANT_SCORE_TYPE,
  SET_SELECTED_DEEPSEA_FEATURE_SCORE_TYPE,
  RESET_DEEPSEA_STATE,
} from '../actions/DeepSEAActions';
import { DEEPSEA_COOKIE_NAME } from '../settings';

const defaultDeepSEAState = {
  features: [],
  expectoFeatures: [],
  featurePredictions: [],
  variantScores: {},
  scoreList: [],
  models: [],
  selectedJob: {},
  jobs: {},
  jobValidationErrors: {},
  in_silico_mutagenesis: {},
  selectedVariantScoreType: {},
  selectedFeatureScoreType: {},
  isFetchingFeaturePredictions: false,
};

function deepsea(state = defaultDeepSEAState, action) {
  switch (action.type) {
    case RECEIVE_DEEPSEA_FEATURE_PREDICTIONS:
      return Object.assign({}, state, {
        featurePredictions: action.featurePredictions,
        isFetchingFeaturePredictions: action.isFetchingFeaturePredictions,
      });
    case RECEIVE_DEEPSEA_FEATURES:
      return Object.assign({}, state, {
        features: action.features.model_features,
        expectoFeatures: action.features.expecto_features,
      });
    case RECEIVE_DEEPSEA_MODELS:
      return Object.assign({}, state, {
        models: action.models,
      });
    case RECEIVE_DEEPSEA_FEATURES_AND_PREDICTIONS:
      return Object.assign({}, state, {
        featurePredictions: action.featurePredictions,
        features: action.features.model_features,
        expectoFeatures: action.features.expecto_features,
        featureQuerySuccess: action.featureQuerySuccess,
        isFetchingFeaturePredictions: action.isFetchingFeaturePredictions,
      });
    case SET_SELECTED_DEEPSEA_JOB:
      return Object.assign({}, state, {
        selectedJob: action.job,
      });
    case RECEIVE_DEEPSEA_JOB: {
      const newJobs = Object.assign({}, state.jobs, action.job);
      setCookie(DEEPSEA_COOKIE_NAME, newJobs);

      return Object.assign({}, state, {
        jobs: newJobs,
      });
    }
    case SET_DEEPSEA_JOBS: {
      setCookie(DEEPSEA_COOKIE_NAME, action.jobs);

      return Object.assign({}, state, {
        jobs: action.jobs,
      });
    }
    case SET_DEEPSEA_JOB_VALIDATION_ERRORS:
      return Object.assign({}, state, {
        jobValidationErrors: action.errors,
      });
    case SET_SELECTED_DEEPSEA_VARIANT_SCORE_TYPE:
      return Object.assign({}, state, {
        selectedVariantScoreType: action.selectedVariantScoreType,
      });
    case SET_SELECTED_DEEPSEA_FEATURE_SCORE_TYPE:
      return Object.assign({}, state, {
        selectedFeatureScoreType: action.selectedFeatureScoreType,
      });
    case REQUEST_INSILICO_FEATURE_DATA:
      return Object.assign({}, state, {
        in_silico_mutagenesis: Object.assign({}, state.in_silico_mutagenesis, {
          isFetching: true,
        }),
      });
    case REQUEST_DEEPSEA_FEATURE_PREDICTIONS:
      return Object.assign({}, state, {
        isFetchingFeaturePredictions: action.isFetchingFeaturePredictions,
      });
    case SET_INSILICO_FEATURE_DATA:
      return Object.assign({}, state, {
        in_silico_mutagenesis: Object.assign({}, state.in_silico_mutagenesis, {
          [action.sequenceName]: Object.assign(
            {},
            state.in_silico_mutagenesis[action.sequenceName],
            { [action.featureName]: { ...action.featureData } },
          ),
          isFetching: false,
        }),
      });
    case SET_SELECTED_INSILICO_DATA:
      return Object.assign({}, state, {
        in_silico_mutagenesis: Object.assign({}, state.in_silico_mutagenesis, {
          selected: {
            featureName: action.featureName,
            sequenceName: action.sequenceName,
          },
        }),
      });
    case RECEIVE_DEEPSEA_JOB_FEATURE_PREDICTIONS:
      return Object.assign({}, state, {
        featurePredictions: action.featurePredictions,
        isFetchingFeaturePredictions: action.isFetchingFeaturePredictions,
      });
    case RECEIVE_DEEPSEA_VARIANT_SCORES:
      return Object.assign({}, state, {
        variantScores: action.variantScores,
      });
    case RECEIVE_DEEPSEA_SCORE_LIST:
      return Object.assign({}, state, {
        scoreList: action.scoreList,
      });
    case RESET_DEEPSEA_STATE:
      return Object.assign({}, defaultDeepSEAState, {
        jobs: state.jobs,
      });
    default:
      return state;
  }
}

const DeepSEAReducers = { deepsea };

export default DeepSEAReducers;
